import {
   combineTransactionSteps,
   Editor,
   findChildrenInRange,
   getAttributes,
   getChangedRanges,
   getMarksBetween,
   Mark,
   markPasteRule,
   mergeAttributes,
   NodeWithPos,
   PasteRuleMatch,
   Range
} from "@tiptap/core";
import { MarkType, Mark as PMMark, Slice } from "@tiptap/pm/model";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { AppStore } from "app/_contexts/ReduxProvider";
import ILog from "app/_lib/global/Log";
import { apiNext } from "app/_services/redux/api/apiNext";
import { find, MultiToken, tokenize } from "linkifyjs";
import { v4 } from "uuid";
import getLinkPreview from "../../_actions/getLinkPreview";
import { isPotluckDeliverableTagURL } from "../../_helpers/handleRegex";
import { FocusItemMarkAttributes } from "../nodes/defaultNodeAttributes";
declare module "@tiptap/core" {
   interface Commands<ReturnType> {
      focusItem: {
         addOverlapMark: ({ attributes, fromOverride, toOverride }: { attributes: FocusItemMarkAttributes; fromOverride?: number; toOverride?: number }) => ReturnType;
         /**
          import { getAttributes } from "@tiptap/core";
          * Unset a focusItem (remove)
          */
         unsetFocusItem: (focusItemId: string) => ReturnType;
         focusOnFocusItem: ({ focusItemId, editor }: { focusItemId: string; editor: Editor }) => void;
         setFocusItemsAttributes: ({
            focusItemId,
            attributes,
            documentId,
            fetchLinkPreview
         }: {
            focusItemId: string;
            attributes: FocusItemMarkAttributes;
            documentId: string | null;
            fetchLinkPreview: boolean;
         }) => ReturnType;
      };
   }
}

export interface MarkWithRange {
   mark: PMMark;
   range: Range;
   newAttrs: FocusItemMarkAttributes | undefined;
}
export interface MarkWithRangeDefined {
   mark: PMMark;
   range: Range;
   newAttrs: FocusItemMarkAttributes;
}
export interface NodeWithRange {
   node: Node;
   range: Range;
}

export interface FocusItemOptions {
   store: AppStore | null;
   HTMLAttributes: Record<string, any>;
   onFocusItemActivated: ({ focusItemId, editor }: { focusItemId: string; editor: Editor }) => void;
   onFocusItemUpdated: ({ editor }: { editor: Editor }) => void;
   /**
    * If enabled, the extension will automatically add links as you type.
    * @default true
    * @example false
    */
   autolink: boolean;

   /**
    * An array of custom protocols to be registered with linkifyjs.
    * @default []
    * @example ['ftp', 'git']
    */
   protocols: Array<LinkProtocolOptions | string>;

   /**
    * Default protocol to use when no protocol is specified.
    * @default 'http'
    */
   defaultProtocol: string;
   /**
    * If enabled, links will be opened on click.
    * @default true
    * @example false
    */
   openOnClick: boolean | DeprecatedOpenWhenNotEditable;
   /**
    * Adds a link to the current selection if the pasted content only contains an url.
    * @default true
    * @example false
    */
   linkOnPaste: boolean;

   /**
    * A validation function which is used for configuring link verification for preventing XSS attacks.
    * Only modify this if you know what you're doing.
    *
    * @returns {boolean} `true` if the URL is valid, `false` otherwise.
    *
    * @example
    * isAllowedUri: (url, { defaultValidate, protocols, defaultProtocol }) => {
    * return url.startsWith('./') || defaultValidate(url)
    * }
    */
   isAllowedUri: (
      /**
       * The URL to be validated.
       */
      url: string,
      ctx: {
         /**
          * The default validation function.
          */
         defaultValidate: (url: string) => boolean;
         /**
          * An array of allowed protocols for the URL (e.g., "http", "https"). As defined in the `protocols` option.
          */
         protocols: Array<LinkProtocolOptions | string>;
         /**
          * A string that represents the default protocol (e.g., 'http'). As defined in the `defaultProtocol` option.
          */
         defaultProtocol: string;
      }
   ) => boolean;

   /**
    * Determines whether a valid link should be automatically linked in the content.
    *
    * @param {string} url - The URL that has already been validated.
    * @returns {boolean} - True if the link should be auto-linked; false if it should not be auto-linked.
    */
   shouldAutoLink: (url: string) => boolean;
}

export interface FocusItemStorage {
   activeFocusItemId: string | null;
   focusedTagId: string | null;
   hasTargets: boolean;
}

export type NullableMarkWithRange = {
   mark: PMMark | undefined;
   range: Range;
   newAttrs: FocusItemMarkAttributes;
};

export const FocusItemExtension = Mark.create<FocusItemOptions, FocusItemStorage>({
   name: "focusItem",
   priority: 1000,
   excludes: "",
   keepOnSplit: true,
   addOptions() {
      return {
         HTMLAttributes: {},
         onFocusItemActivated: () => {},
         onFocusItemUpdated({ editor }) {},
         isAllowedUri: (url, ctx) => !!isAllowedUri(url, ctx.protocols),
         protocols: ["https"],
         defaultProtocol: "https",
         autolink: true,
         openOnClick: true,
         linkOnPaste: true,
         shouldAutoLink: (url) => {
            return false;
         },
         store: null
         // validate: (url) => {
         //    return true;
         // }
      };
   },
   addAttributes() {
      const id = v4();
      return {
         focusItemId: {
            default: id,
            parseHTML: (el) => (el as HTMLSpanElement).getAttribute("data-focus-item-id"),
            renderHTML: (attrs) => ({ "data-focus-item-id": attrs.focusItemId })
         },
         href: {
            default: null,
            parseHTML: (el) => (el as HTMLSpanElement).getAttribute("data-focus-item-href"),
            renderHTML: (attrs) => ({ "data-focus-item-href": attrs.href })
         },

         type: {
            default: null,
            parseHTML: (el) => (el as HTMLSpanElement).getAttribute("data-focus-item-type"),
            renderHTML: (attrs) => ({ "data-focus-item-type": attrs.type })
         },
         base64: {
            default: null,
            parseHTML: (el) => (el as HTMLSpanElement).getAttribute("data-focus-item-base64"),
            renderHTML: (attrs) => ({ "data-focus-item-base64": attrs.base64 })
         },
         title: {
            default: null,
            parseHTML: (el) => (el as HTMLSpanElement).getAttribute("data-focus-item-title"),
            renderHTML: (attrs) => ({ "data-focus-item-title": attrs.title })
         },
         documentId: {
            default: null,
            parseHTML: (el) => (el as HTMLSpanElement).getAttribute("data-focus-item-document-id"),
            renderHTML: (attrs) => ({ "data-focus-item-document-id": attrs.documentId })
         },
         tagId: {
            default: null,
            parseHTML: (el) => (el as HTMLSpanElement).getAttribute("data-focus-item-tag-id"),
            renderHTML: (attrs) => ({ "data-focus-item-tag-id": attrs.tagId })
         },
         personId: {
            default: null,
            parseHTML: (el) => (el as HTMLSpanElement).getAttribute("data-focus-item-person-id"),
            renderHTML: (attrs) => ({ "data-focus-item-person-id": attrs.personId })
         },

         chatIds: {
            default: [id],
            parseHTML: (el) => JSON.parse((el as HTMLSpanElement).getAttribute("data-focus-item-comment-thread-ids") || "[]"),
            renderHTML: (attrs) => ({ "data-focus-item-comment-thread-ids": JSON.stringify(attrs.chatIds) })
         }
      };
   },
   parseHTML() {
      return [
         {
            tag: "span[data-focus-item-id]",
            getAttrs: (el) => !!(el as HTMLSpanElement).getAttribute("data-focus-item-id")?.trim() && null
         }
      ];
   },
   renderHTML({ HTMLAttributes, mark }) {
      const { focusItemId, type, chatIds, href } = mark.attrs as FocusItemMarkAttributes;
      //@ts-expect-error
      const gestureId = this.editor?.options.editorProps.attributes?.gestureId;
      let classes = ` focus-item `;
      if (type === "link") {
         classes += " underline text-blue-500 text-bold decoration-blue-500 ";
         return [
            "span",
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
               class: classes
            }),
            ["a", { href, target: "_blank", rel: "noopener noreferrer" }, 0]
         ];
      } else if (type === "comment") {
         if (!!gestureId) {
            if (chatIds?.includes(gestureId)) {
               classes += " bg-amber-200 print:bg-inherit";
            } else {
               ILog.v("NOT WANTED", { activeFocusItemId: this.storage.activeFocusItemId, focusItemId, gestureId });
               classes += " bg-amber-100  print:bg-inherit ";
            }
         } else {
            ILog.v("NOT WANTED", { activeFocusItemId: this.storage.activeFocusItemId, focusItemId, gestureId });
            classes += " bg-amber-100  print:bg-inherit ";
         }
      }
      return [
         "span",
         mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
            class: classes
         }),
         0
      ];
   },
   onSelectionUpdate() {
      const { $from } = this.editor.state.selection;

      const marks = $from.marks();
      ILog.v("onSelectionUpdate", { marks });
      if (!marks.length) {
         this.storage.activeFocusItemId = null;

         return;
      }

      const focusItemMark = this.editor.schema.marks.focusItem;

      const activeFocusItemMark = marks.find((mark) => mark.type === focusItemMark);
      ILog.v("onSelectionUpdate", { activeFocusItemMark });
      if (!activeFocusItemMark) {
         this.storage.activeFocusItemId = null;
         return;
      }

      this.storage.activeFocusItemId = activeFocusItemMark?.attrs.focusItemId || null;
      ILog.v("onSelectionUpdate", { activeFocusItemId: this.storage.activeFocusItemId, activeFocusItemMark });
      if (!!this.storage.activeFocusItemId) {
         this.options.onFocusItemActivated({ focusItemId: this.storage.activeFocusItemId, editor: this.editor });
      }
   },
   addStorage() {
      return {
         activeFocusItemId: null,
         focusedTagId: null,
         hasTargets: true
      };
   },
   addCommands() {
      return {
         addOverlapMark:
            ({ attributes, fromOverride, toOverride }) =>
            ({ tr, commands, editor, state, view, chain, dispatch }) => {
               const selection = this.editor.state.selection;
               const { from: selectionFrom, to: selectionTo } = selection;
               let finalFrom = fromOverride || selectionFrom;
               let finalTo = toOverride || selectionTo;
               if (finalFrom === finalTo) {
                  tr.setMeta("focusItem", { reload: true });
                  return false;
               }

               function mergeMarks(range: Range, marks: PMMark[]) {
                  ILog.v("mergeMarks1", { selection, marks, range });
                  let merged_chatIds: string[] = [];

                  marks.forEach((mark) => {
                     const attrs = mark.attrs as FocusItemMarkAttributes;
                     merged_chatIds = [...merged_chatIds, ...attrs.chatIds];
                  });

                  let deduped_chatIds = Array.from(new Set(merged_chatIds));
                  const defaultAttrs = marks.find((mark) => !!mark.attrs.focusItemId)?.attrs as FocusItemMarkAttributes;
                  ILog.v("mergeMarks2", { range, deduped_chatIds, defaultAttrs });
                  tr.removeMark(range.from, range.to, editor.schema.marks.focusItem);
                  const steps = tr.steps;
                  ILog.v("mergeMarks3", { steps });
                  const res = tr.addMark(
                     range.from,
                     range.to,
                     editor.schema.marks.focusItem.create({
                        ...defaultAttrs,
                        chatIds: deduped_chatIds
                     })
                  );
                  ILog.v("mergeMarks4", { res });
               }

               function addMark(attrs: FocusItemMarkAttributes, range: Range) {
                  //deduplicate newAttrs.chatIds
                  const deduped_chatIds = Array.from(new Set(attrs.chatIds));
                  // deduplicate newAttrs.comments.comment_created_at

                  ILog.v("addMark1", { range, deduped_chatIds });
                  const res = tr.addMark(range.from, range.to, editor.schema.marks.focusItem.create({ ...attrs, chatIds: deduped_chatIds }));
                  ILog.v("addMark2", { res });
               }

               addMark(attributes, { from: finalFrom, to: finalTo });

               tr.doc.descendants((node, pos, parent, index) => {
                  let fiMarks: PMMark[] = node.marks.filter((mark) => mark.type.name === this.name);
                  if (fiMarks.length > 1) {
                     //@ts-expect-error
                     fiMarks = fiMarks.map((mark) => {
                        const attrs = mark.attrs as FocusItemMarkAttributes;
                        if (!!attrs.focusItemId) return mark;
                        return { ...mark, attrs: attributes };
                     });
                     ILog.v("fiMarks", { fiMarks });
                     const range = {
                        from: pos,
                        to: pos + node.nodeSize
                     };
                     let diffFrom = range.from - finalFrom;
                     let diffTo = range.to - finalTo;
                     ILog.v("addOverlapMark", { fiMarks, from: pos, to: pos + node.nodeSize, diffFrom, diffTo, selection, parent, index });

                     mergeMarks(range, fiMarks);
                  } else {
                     ILog.v("addOverlapMark_descendants", { node, parent, fiMarks });
                  }
               });

               ILog.v("addOverlapMark_FINAL", { attributes, selection, tr });

               tr.setMeta("focusItem", { reload: true });
               return dispatch?.(tr);
            },

         setFocusItemsAttributes:
            ({ focusItemId, attributes, documentId, fetchLinkPreview }) =>
            ({ tr, commands, editor, state, view, chain, dispatch }) => {
               if (!focusItemId) return false;

               ILog.v("setFocusItemsAttributes", { focusItemId, attributes });
               let { type, href, base64, title } = attributes;

               if (type === "link" && !!href && fetchLinkPreview) {
                  this.options.store?.dispatch(
                     apiNext.endpoints.action.initiate(async () => {
                        const res = await getLinkPreview({ url: href, markId: focusItemId });
                        attributes.base64 = res?.data?.base64 || undefined;
                        attributes.title = res?.data?.title || undefined;
                        editor.commands.setFocusItemsAttributes({ focusItemId, attributes, documentId, fetchLinkPreview: false });
                        ILog.v("setFocusItemsAttributes_getLinkPreview", { editor, focusItemId, attributes, documentId, fetchLinkPreview });
                        return res;
                     })
                  );
               }
               const focusItemMarksWithRange: MarkWithRange[] = [];

               tr.doc.descendants((node, pos) => {
                  const focusItemMark = node.marks.find((mark) => mark.type.name === "focusItem" && mark.attrs.focusItemId === focusItemId);
                  if (!focusItemMark) return;
                  focusItemMarksWithRange.push({
                     mark: focusItemMark,
                     range: {
                        from: pos,
                        to: pos + node.nodeSize
                     },
                     newAttrs: attributes
                  });
               });
               focusItemMarksWithRange.forEach(({ mark, range }) => {
                  ILog.v("setFocusItemsAttributes", { mark, range });
                  tr.addMark(range.from, range.to, editor.schema.marks.focusItem.create({ ...mark.attrs, ...attributes })).setMeta("focusItem", { reload: true });
               });
               tr.setMeta("focusItem", { reload: true });
               return dispatch?.(tr);
            },
         focusOnFocusItem: ({ focusItemId, editor }) => {
            this.options.onFocusItemActivated({ focusItemId, editor: this.editor });
         },

         unsetFocusItem:
            (gestureId) =>
            ({ tr, dispatch, editor }) => {
               if (!gestureId) return false;

               const removeMarks: MarkWithRange[] = [];
               const setMarks: MarkWithRange[] = [];

               tr.doc.descendants((node, pos) => {
                  let focusItemMark = node.marks.find((mark) => {
                     if (mark.type.name === "focusItem") {
                        ILog.v("unsetFocusItem", { gestureId, markFocusItemId: mark.attrs.focusItemId });
                     }
                     const bool =
                        mark.type.name === "focusItem" && ((mark.attrs as FocusItemMarkAttributes).chatIds?.includes(gestureId) || (mark.attrs as FocusItemMarkAttributes).chatIds?.length === 0);
                     ILog.v("unsetFocusItem_TARGET", { gestureId, bool, mark });
                     return !!bool;
                  });

                  if (!focusItemMark) return;
                  let ids = (focusItemMark.attrs as FocusItemMarkAttributes).chatIds;

                  if (!!ids) {
                     ILog.v("unsetFocusItem1", { gestureId, ids });
                     let newIds = ids.filter((id) => id !== gestureId);

                     if (newIds.length === 0) {
                        ILog.v("unsetFocusItem2_REMOVE", { gestureId, newIds });
                        removeMarks.push({
                           mark: focusItemMark,
                           range: {
                              from: pos,
                              to: pos + node.nodeSize
                           },
                           newAttrs: undefined
                        });
                     } else {
                        ILog.v("unsetFocusItem3_SET", { gestureId, newIds });
                        setMarks.push({
                           mark: focusItemMark,
                           range: {
                              from: pos,
                              to: pos + node.nodeSize
                           },
                           newAttrs: {
                              ...(focusItemMark.attrs as FocusItemMarkAttributes),
                              chatIds: newIds
                           }
                        });
                     }
                  } else {
                     throw new Error("No commentThreadId found in focusItemMark");
                     // ILog.v("unsetFocusItem4_SET", { gestureId, ids });
                     // removeMarks.push({
                     //    mark: focusItemMark,
                     //    range: {
                     //       from: pos,
                     //       to: pos + node.nodeSize
                     //    }
                     // });
                  }
               });
               if (removeMarks.length === 0 && setMarks.length === 0) {
                  ILog.v("unsetFocusItem5_nothing to set", { gestureId });
                  return false;
               }
               //@ts-expect-error
               ILog.v("unsetFocusItem", { gestureId, removeMarks, editor: this.editor.options.editorProps.attributes?.editorDocumentId, setMarks });
               removeMarks.forEach(({ mark, range }) => {
                  tr.removeMark(range.from, range.to, mark).setMeta("focusItem", { reload: true });
               });
               setMarks.forEach(({ mark, range, newAttrs }) => {
                  ILog.v("setMark", { mark, range });
                  tr.addMark(range.from, range.to, editor.schema.marks.focusItem.create({ ...newAttrs })).setMeta("focusItem", { reload: true });
               });
               this.options.onFocusItemUpdated({ editor: this.editor });
               tr.setMeta("focusItem", { reload: true });
               return dispatch?.(tr);
            }
      };
   },
   addPasteRules() {
      return [
         markPasteRule({
            find: (text) => {
               const foundLinks: PasteRuleMatch[] = [];

               ILog.v("FocusItem_markPasteRule", { text });
               if (text) {
                  const { protocols, defaultProtocol } = this.options;
                  const links = find(text).filter(
                     (item) =>
                        item.isLink &&
                        this.options.isAllowedUri(item.value, {
                           defaultValidate: (href) => !!isAllowedUri(href, protocols),
                           protocols,
                           defaultProtocol
                        })
                  );

                  if (links.length) {
                     links.forEach((link) =>
                        foundLinks.push({
                           text: link.value,
                           data: {
                              href: link.href,
                              type: "link"
                           },
                           index: link.start
                        })
                     );
                  }
               }

               return foundLinks;
            },
            type: this.type,
            getAttributes: (match) => {
               return {
                  href: match.data?.href,
                  type: match.data?.type,
                  base64: null
               };
            }
         })
      ];
   },

   addProseMirrorPlugins() {
      const plugins: Plugin[] = [];
      const { protocols, defaultProtocol } = this.options;

      if (this.options.autolink) {
         plugins.push(
            autolink({
               type: this.type,
               defaultProtocol: this.options.defaultProtocol,
               validate: (url) =>
                  this.options.isAllowedUri(url, {
                     defaultValidate: (href) => !!isAllowedUri(href, protocols),
                     protocols,
                     defaultProtocol
                  }),
               shouldAutoLink: this.options.shouldAutoLink,
               editor: this.editor
            })
         );
      }

      if (this.options.openOnClick === true) {
         plugins.push(
            clickHandler({
               type: this.type,
               editor: this.editor
            })
         );
      }

      if (this.options.linkOnPaste) {
         plugins.push(
            pasteHandler({
               editor: this.editor,
               defaultProtocol: this.options.defaultProtocol,
               type: this.type
            })
         );
      }

      return plugins;
   }
});

const ATTR_WHITESPACE = /[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205F\u3000]/g;

export function isAllowedUri(uri: string | undefined, protocols?: LinkOptions["protocols"]) {
   const allowedProtocols: string[] = ["http", "https", "ftp", "ftps", "mailto", "tel", "callto", "sms", "cid", "xmpp"];
   ILog.v("isAllowedUri", { uri, protocols });
   if (protocols) {
      protocols.forEach((protocol) => {
         const nextProtocol = typeof protocol === "string" ? protocol : protocol.scheme;

         if (nextProtocol) {
            allowedProtocols.push(nextProtocol);
         }
      });
   }
   if (!uri) return false;
   const potluckURL = isPotluckDeliverableTagURL(uri);
   ILog.v("isAllowedUri", { uri, potluckURL });
   return (
      !potluckURL ||
      uri.replace(ATTR_WHITESPACE, "").match(
         new RegExp(
            // eslint-disable-next-line no-useless-escape
            `^(?:(?:${allowedProtocols.join("|")}):|[^a-z]|[a-z0-9+.\-]+(?:[^a-z+.\-:]|$))`,
            "i"
         )
      )
   );
}
export interface LinkProtocolOptions {
   /**
    * The protocol scheme to be registered.
    * @default '''
    * @example 'ftp'
    * @example 'git'
    */
   scheme: string;

   /**
    * If enabled, it allows optional slashes after the protocol.
    * @default false
    * @example true
    */
   optionalSlashes?: boolean;
}

export const pasteRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z]{2,}\b(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)(?:[-a-zA-Z0-9@:%._+~#=?!&/]*)/gi;

/**
 * @deprecated The default behavior is now to open links when the editor is not editable.
 */
type DeprecatedOpenWhenNotEditable = "whenNotEditable";

export interface LinkOptions {
   /**
    * If enabled, the extension will automatically add links as you type.
    * @default true
    * @example false
    */
   autolink: boolean;

   /**
    * An array of custom protocols to be registered with linkifyjs.
    * @default []
    * @example ['ftp', 'git']
    */
   protocols: Array<LinkProtocolOptions | string>;

   /**
    * Default protocol to use when no protocol is specified.
    * @default 'http'
    */
   defaultProtocol: string;
   /**
    * If enabled, links will be opened on click.
    * @default true
    * @example false
    */
   openOnClick: boolean | DeprecatedOpenWhenNotEditable;
   /**
    * Adds a link to the current selection if the pasted content only contains an url.
    * @default true
    * @example false
    */
   linkOnPaste: boolean;

   /**
    * HTML attributes to add to the link element.
    * @default {}
    * @example { class: 'foo' }
    */
   HTMLAttributes: Record<string, any>;

   /**
    * @deprecated Use the `shouldAutoLink` option instead.
    * A validation function that modifies link verification for the auto linker.
    * @param url - The url to be validated.
    * @returns - True if the url is valid, false otherwise.
    */
   // validate: (url: string) => boolean;

   /**
    * A validation function which is used for configuring link verification for preventing XSS attacks.
    * Only modify this if you know what you're doing.
    *
    * @returns {boolean} `true` if the URL is valid, `false` otherwise.
    *
    * @example
    * isAllowedUri: (url, { defaultValidate, protocols, defaultProtocol }) => {
    * return url.startsWith('./') || defaultValidate(url)
    * }
    */
   isAllowedUri: (
      /**
       * The URL to be validated.
       */
      url: string,
      ctx: {
         /**
          * The default validation function.
          */
         defaultValidate: (url: string) => boolean;
         /**
          * An array of allowed protocols for the URL (e.g., "http", "https"). As defined in the `protocols` option.
          */
         protocols: Array<LinkProtocolOptions | string>;
         /**
          * A string that represents the default protocol (e.g., 'http'). As defined in the `defaultProtocol` option.
          */
         defaultProtocol: string;
      }
   ) => boolean;

   /**
    * Determines whether a valid link should be automatically linked in the content.
    *
    * @param {string} url - The URL that has already been validated.
    * @returns {boolean} - True if the link should be auto-linked; false if it should not be auto-linked.
    */
   shouldAutoLink: (url: string) => boolean;
}

type ClickHandlerOptions = {
   type: MarkType;
   editor: Editor;
};

export function clickHandler(options: ClickHandlerOptions): Plugin {
   return new Plugin({
      key: new PluginKey("handleClickLink"),
      props: {
         // handleDoubleClick(view, pos, event) {
         //    ILog.v("handleDoubleClick", { event, view, pos });
         //    if (event.button !== 0) {
         //       return false;
         //    }

         //    const attrs = getAttributes(view.state, options.type.name);

         //    const href = attrs.href;
         //    const target = attrs.target || "_blank";

         //    ILog.v("handleDoubleClick", { href, target });
         //    if (target && href) {
         //       window.open(href, target);

         //       return true;
         //    }

         //    return false;
         // },

         handleClick: (view, pos, event) => {
            ILog.v("handleClickLink", { event, view, pos });
            if (event.button !== 0) {
               return false;
            }

            if (view.editable /* && options.editor.isFocused */) {
               return false;
            }

            // let a = event.target as HTMLElement;
            // const els = [];

            // while (a.nodeName !== "DIV") {
            //    els.push(a);
            //    a = a.parentNode as HTMLElement;
            // }

            // if (!els.find((value) => value.nodeName === "A")) {
            //    return false;
            // }

            const attrs = getAttributes(view.state, options.type.name);

            const href = attrs.href;
            const target = attrs.target || "_blank";

            ILog.v("handleClickLink", { href, target });
            if (target && href) {
               window.open(href, target);

               return true;
            }

            return false;
         }
      }
   });
}

type PasteHandlerOptions = {
   editor: Editor;
   defaultProtocol: string;
   type: MarkType;
};

export function pasteHandler(options: PasteHandlerOptions): Plugin {
   return new Plugin({
      key: new PluginKey("handlePasteLink"),
      // appendTransaction(transactions, oldState, newState) {
      //    ILog.v("appendTransaction1", { transactions, oldState, newState });
      //    return transactions.reduce((tr, transaction) => {
      //       // if (transaction.getMeta("focusItem")?.reload) {
      //       //    ILog.v("appendTransaction2", { transaction });
      //       //    // tr.setMeta("focusItem", { reload: true });
      //       //    return tr;
      //       // }
      //       return tr;
      //    }, newState.tr);
      // },
      props: {
         transformPasted(slice, view) {
            const { state } = view;
            const { selection } = state;
            const { empty } = selection;

            ILog.v("transformPasted1", { slice, selection, empty });
            if (empty) {
               let content = slice.content;

               content.descendants((node, pos, parent) => {
                  node.marks.forEach((mark) => {
                     if (mark.type.name === options.type.name && !mark.attrs.href) {
                        return;
                     }
                     // We're allowed to mutate this slice directly, because it's only created for this transaction
                     const id = v4();
                     //@ts-expect-error
                     mark.attrs = { ...mark.attrs, chatIds: [id], focusItemId: id };
                  });
               });

               return new Slice(content, slice.openStart, slice.openEnd);
            }
            let textContent = "";

            slice.content.forEach((node) => {
               textContent += node.textContent;
            });

            const link = find(textContent, { defaultProtocol: options.defaultProtocol }).find((item) => item.isLink && item.value === textContent);

            ILog.v("transformPasted2", { textContent, link });
            if (!textContent || !link) {
               return slice;
            }

            return slice;
         },
         handlePaste: (view, event, slice) => {
            const { state } = view;
            const { selection } = state;
            const { empty } = selection;

            ILog.v("handlePasteLink1", { event, slice, selection, empty });

            if (empty) {
               // Already handled by transformPasted
               return false;
            }
            let textContent = "";

            slice.content.forEach((node) => {
               textContent += node.textContent;
            });

            const link = find(textContent, { defaultProtocol: options.defaultProtocol }).find((item) => item.isLink && item.value === textContent);

            ILog.v("handlePasteLink3", { textContent, link });
            if (!textContent || !link) {
               return false;
            }
            ILog.v("handlePasteLink4", { textContent, link });
            const id = v4();
            return options.editor.commands.addOverlapMark({
               attributes: { href: link.href, type: "link", base64: undefined, chatIds: [id], focusItemId: id, title: undefined, personId: undefined, tagId: undefined }
            });
         }
      }
   });
}

/**
 * Check if the provided tokens form a valid link structure, which can either be a single link token
 * or a link token surrounded by parentheses or square brackets.
 *
 * This ensures that only complete and valid text is hyperlinked, preventing cases where a valid
 * top-level domain (TLD) is immediately followed by an invalid character, like a number. For
 * example, with the `find` method from Linkify, entering `example.com1` would result in
 * `example.com` being linked and the trailing `1` left as plain text. By using the `tokenize`
 * method, we can perform more comprehensive validation on the input text.
 */
function isValidLinkStructure(tokens: Array<ReturnType<MultiToken["toObject"]>>) {
   if (tokens.length === 1) {
      return tokens[0].isLink;
   }

   if (tokens.length === 3 && tokens[1].isLink) {
      return ["()", "[]"].includes(tokens[0].value + tokens[2].value);
   }

   return false;
}

type AutolinkOptions = {
   type: MarkType;
   defaultProtocol: string;
   validate: (url: string) => boolean;
   shouldAutoLink: (url: string) => boolean;
   editor: Editor;
};

/**
 * This plugin allows you to automatically add links to your editor.
 * @param options The plugin options
 * @returns The plugin instance
 */
export function autolink(options: AutolinkOptions): Plugin {
   return new Plugin({
      key: new PluginKey("autolink"),
      appendTransaction: (transactions, oldState, newState) => {
         ILog.v("autolink1_appendTransaction", { transactions, oldState, newState });
         /**
          * Does the transaction change the document?
          */
         const docChanges = transactions.some((transaction) => transaction.docChanged) && !oldState.doc.eq(newState.doc);

         /**
          * Prevent autolink if the transaction is not a document change or if the transaction has the meta `preventAutolink`.
          */
         const preventAutolink = transactions.some((transaction) => transaction.getMeta("preventAutolink"));

         /**
          * Prevent autolink if the transaction is not a document change
          * or if the transaction has the meta `preventAutolink`.
          */
         if (!docChanges || preventAutolink) {
            ILog.v("autolink2_appendTransaction", { docChanges, preventAutolink });
            return;
         }
         const { tr } = newState;
         const transform = combineTransactionSteps(oldState.doc, [...transactions]);
         const changes = getChangedRanges(transform);

         //https://www.google.com/
         ILog.v("autolink3_appendTransaction", { transform, changes });
         changes.forEach(({ newRange }) => {
            // Now let’s see if we can add new links.
            const nodesInChangedRanges = findChildrenInRange(newState.doc, newRange, (node) => node.isTextblock);

            let textBlock: NodeWithPos | undefined;
            let textBeforeWhitespace: string | undefined;

            const blockSeparatorBool = newState.doc.textBetween(newRange.from, newRange.to, " ", " ").endsWith(" ");

            if (nodesInChangedRanges.length > 1) {
               ILog.v("autolink4_appendTransaction", "Multiple text blocks in the changed range.");
               // Grab the first node within the changed ranges (ex. the first of two paragraphs when hitting enter).
               textBlock = nodesInChangedRanges[0];
               textBeforeWhitespace = newState.doc.textBetween(textBlock.pos, textBlock.pos + textBlock.node.nodeSize, undefined, " ");
            } else if (
               nodesInChangedRanges.length &&
               // We want to make sure to include the block seperator argument to treat hard breaks like spaces.
               blockSeparatorBool
            ) {
               ILog.v("autolink5_appendTransaction", "Single text block in the changed range.");
               textBlock = nodesInChangedRanges[0];
               textBeforeWhitespace = newState.doc.textBetween(textBlock.pos, newRange.to, undefined, " ");
            } else {
               ILog.v("autolink6_appendTransaction", "No text block in the changed range.");
            }

            if (textBlock && textBeforeWhitespace) {
               const wordsBeforeWhitespace = textBeforeWhitespace.split(" ").filter((s) => s !== "");
               ILog.v("autolink6_appendTransaction", { wordsBeforeWhitespace, textBeforeWhitespace });
               if (wordsBeforeWhitespace.length <= 0) {
                  ILog.v("autolink7_appendTransaction", "No words before whitespace.");
                  return false;
               }

               const lastWordBeforeSpace = wordsBeforeWhitespace[wordsBeforeWhitespace.length - 1];
               const lastWordAndBlockOffset = textBlock.pos + textBeforeWhitespace.lastIndexOf(lastWordBeforeSpace);

               if (!lastWordBeforeSpace) {
                  ILog.v("autolink8_appendTransaction", "No last word before space.");
                  return false;
               }

               const linksBeforeSpace = tokenize(lastWordBeforeSpace).map((t) => t.toObject(options.defaultProtocol));

               if (!isValidLinkStructure(linksBeforeSpace)) {
                  ILog.v("autolink9_appendTransaction", "Invalid link structure.");
                  return false;
               }

               linksBeforeSpace
                  .filter((link) => {
                     const bool = link.isLink;
                     ILog.v("autolink10_appendTransaction", { link, bool });
                     return bool;
                  })
                  // Calculate link position.
                  .map((link) => ({
                     ...link,
                     from: lastWordAndBlockOffset + link.start + 1,
                     to: lastWordAndBlockOffset + link.end + 1
                  }))
                  // ignore link inside code mark
                  .filter((link) => {
                     if (!newState.schema.marks.code) {
                        ILog.v("autolink10_appendTransaction", "No code mark found.");
                        return true;
                     }
                     ILog.v("autolink11_appendTransaction", { link, code: newState.schema.marks.code });

                     return !newState.doc.rangeHasMark(link.from, link.to, newState.schema.marks.code);
                  })
                  // validate link
                  .filter((link) => {
                     const bool = options.validate(link.value);
                     ILog.v("autolink12_appendTransaction", { link, bool });
                     return bool;
                  })
                  // check whether should autolink
                  .filter((link) => {
                     const bool = options.shouldAutoLink(link.value);
                     ILog.v("autolink13_appendTransaction", { link, bool });
                     return bool;
                  })
                  // Add link mark.
                  .forEach((link) => {
                     if (getMarksBetween(link.from, link.to, newState.doc).some((item) => item.mark.type === options.type)) {
                        ILog.w("autolink2_appendTransaction", "Link already exists in the range.");
                        return;
                     }

                     const id = v4();
                     tr.addMark(
                        link.from,
                        link.to,
                        options.type.create({
                           href: link.href,
                           type: "link",
                           base64: null,
                           chatIds: [id],
                           focusItemId: id
                        })
                     );
                     ILog.v("autolink_FINAL_appendTransaction", { link, id });
                     // options.editor.commands.addOverlapMark({
                     //    attributes: { href: link.href, type: "link", base64: undefined, chatIds: [id], focusItemId: id, title: undefined, personId: undefined, tagId: undefined },
                     //    fromOverride: link.from,
                     //    toOverride: link.to
                     // });
                  });
            } else {
               ILog.v("autolink14_appendTransaction", "No text block or text before whitespace.");
            }
         });

         if (!tr.steps.length) {
            ILog.v("autolink_no_steps", "No steps.");
            return;
         }

         return tr;
      }
   });
}
