"use client";
import { apiTeam } from "app/(platform)/workspaces/[campaign_id]/(editor)/activities/teams/_api/team";
import ILog from "app/_lib/global/Log";
import { ActivityPrefix, AttemptType, ControlType, VersionType } from "app/_types/Schema";
import { useContext, useEffect, useMemo, useState } from "react";
import { CampaignContext } from "../../../../../_contexts/CampaignContext";
import useAllCCQueries from "../../../_hooks/useAllCCQueries";
import { ActivitySpecNodeAttributesInitialization } from "../../_components/nodes/defaultNodeAttributes";
import { calculateActivityPrefix } from "../_helpers/calculateActivityType";

interface UseActivityPrefixProps {
   teamId?: string | undefined;
   templateId?: string | undefined;
   attemptId?: string | undefined;
   activityPrefix?: ActivityPrefix | undefined;
   spaceId?: string | undefined;
}
export default function useActivityPrefix({ teamId, templateId, attemptId, activityPrefix, spaceId }: UseActivityPrefixProps) {
   const { campaignId } = useContext(CampaignContext);
   const [type, setType] = useState<ControlType | VersionType | AttemptType | undefined | undefined>(undefined);
   const { prefix } = useMemo(() => {
      if (activityPrefix) {
         return {
            prefix: activityPrefix
         };
      }
      const prefix = calculateActivityPrefix(type);
      return {
         prefix
      };
   }, [type, activityPrefix]);

   const spacePath = useMemo(() => {
      if (templateId) return `/workspaces/${campaignId}/activities/teams/${teamId}/templates/${templateId}/${spaceId}`;
      if (attemptId) return `/workspaces/${campaignId}/activities/teams/${teamId}/attempts/${attemptId}/${spaceId}`;
      return undefined;
   }, [teamId, attemptId, templateId, campaignId, spaceId]);

   const activityType = useMemo(() => {
      if (attemptId) return "attempt";
      if (templateId) return "version";
      if (teamId) return "control";
      return undefined;
   }, [attemptId, templateId]);

   const { connectionRole } = useAllCCQueries({ campaignId, teamId });
   const { data: team } = apiTeam.useGetOneActivityControlQuery({ id: teamId, query: { filter: { id: { _eq: teamId } } }, role: connectionRole }, { skip: !teamId || !connectionRole });
   useEffect(() => {
      if (!type) {
         team?.activity_control_type && setType(team.activity_control_type);
      }
   }, [team?.activity_control_type, type]);
   ILog.v("useActivityPrefix", { spacePath, team, activityPrefix: prefix, activityType, connectionRole });
   return {
      spacePath,
      team,
      activityPrefix: prefix,
      activityType: activityType as ActivitySpecNodeAttributesInitialization["specType"]
   };
}
