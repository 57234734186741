import { HocuspocusProviderWebsocket } from "@hocuspocus/provider";
import { Endpoints } from "app/_lib/global/Endpoints";
import ILog from "app/_lib/global/Log";

interface SetInstanceParametersProps {
   saveContentVersion: boolean;
}
export class HocuspocusWebSocketProviderSingleton {
   private static instance: HocuspocusProviderWebsocket | undefined;

   private constructor() {}

   public static getInstance(parameters: SetInstanceParametersProps): HocuspocusProviderWebsocket {
      if (!HocuspocusWebSocketProviderSingleton.instance) {
         HocuspocusWebSocketProviderSingleton.instance = new HocuspocusProviderWebsocket({
            url: Endpoints.API.Hocuspocus.BaseURL!,
            connect: false,
            maxAttempts: 3,
            parameters,
            onMessage(data) {
               ILog.v("HocuspocusWebSocketProviderSingleton", { data, map: this.providerMap, this: this });
            },
            onClose(data) {
               ILog.v("HocuspocusWebSocketProviderSingleton", { data, map: this.providerMap, this: this });
            },
            onAwarenessChange(data) {
               ILog.v("HocuspocusWebSocketProviderSingleton", { data, map: this.providerMap, this: this });
            },
            onAwarenessUpdate(data) {
               ILog.v("HocuspocusWebSocketProviderSingleton", { data, map: this.providerMap, this: this });
            },
            onOpen(data) {
               ILog.v("HocuspocusWebSocketProviderSingleton", { data, map: this.providerMap, this: this });
            },
            onStatus(data) {
               ILog.v("HocuspocusWebSocketProviderSingleton", { data, map: this.providerMap, this: this });
            },
            onDisconnect(data) {
               ILog.v("HocuspocusWebSocketProviderSingleton", { data, map: this.providerMap, this: this });
            },
            onOutgoingMessage(data) {
               ILog.v("HocuspocusWebSocketProviderSingleton", { data, map: this.providerMap, this: this });
            }
         });
      }

      return HocuspocusWebSocketProviderSingleton.instance;
   }
   // public static setInstanceParameters({ authenticationRequired }: SetInstanceParametersProps) {
   //    if (!this.instance) {
   //       return;
   //    }
   //    this.instance.configuration.parameters = {
   //       authenticationRequired: authenticationRequired
   //    };
   // }
   public static disconnectWebsocketConnection() {
      if (!this.instance) {
         return;
      }
      this.instance.disconnect();
      this.instance = undefined;
   }
}
