"use client";

import { Node, mergeAttributes } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { ReactNodeViewRenderer } from "@tiptap/react";
import getRemSize from "app/_helpers/getRemSize";
import ILog from "app/_lib/global/Log";
import dynamic from "next/dynamic";

const AssetNodeContainer = dynamic(() => import("./AssetNodeContainer"), { ssr: false });

export const AssetNode = Node.create({
   name: "AssetNode",
   group: "block",
   // content: "inline*",
   draggable: true,
   selectable: true,
   isolating: true,
   allowGapCursor: true,

   // addPasteRules() {
   //    return [];
   // },
   addAttributes() {
      return {
         assetNodeId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-asset-node-id"),
            renderHTML: (attrs) => ({ "data-asset-node-id": attrs.assetNodeId })
         },

         dimensions: {
            width: {
               max: {
                  pixels: {
                     default: undefined
                  }
               },
               current: {
                  pixels: {
                     default: getRemSize() * 20
                  }
               },
               min: {
                  pixels: {
                     default: 50
                  }
               }
            },
            height: {
               max: {
                  pixels: {
                     default: undefined
                  }
               },
               current: {
                  pixels: {
                     default: getRemSize() * 20
                  }
               },
               min: {
                  pixels: {
                     default: 50
                  }
               }
            }
         },
         alignment: {
            default: "center",
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-alignment"),
            renderHTML: (attrs) => ({ "data-alignment": attrs.alignment })
         },
         campaignId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-campaign-id"),
            renderHTML: (attrs) => ({ "data-campaign-id": attrs.campaignId })
         },
         teamId: { default: undefined, parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-team-id"), renderHTML: (attrs) => ({ "data-team-id": attrs.teamId }) },
         templateId: { default: undefined, parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-template-id"), renderHTML: (attrs) => ({ "data-template-id": attrs.templateId }) },
         attemptId: { default: undefined, parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-attempt-id"), renderHTML: (attrs) => ({ "data-attempt-id": attrs.attemptId }) },
         editionId: { default: undefined, parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-edition-id"), renderHTML: (attrs) => ({ "data-edition-id": attrs.editionId }) },
         activityPrefix: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-activity-prefix"),
            renderHTML: (attrs) => ({ "data-activity-prefix": attrs.activityPrefix })
         },
         specType: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-spec-type"),
            renderHTML: (attrs) => ({ "data-spec-type": attrs.specType })
         },

         focusDeliverableTag: {
            default: false,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-focus-deliverable-tag") === "true",
            renderHTML: (attrs) => ({ "data-focus-deliverable-tag": attrs.focusDeliverableTag })
         },
         instructionsContent: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-instructions-content"),
            renderHTML: (attrs) => ({ "data-instructions-content": attrs.instructionsContent })
         },
         instructionsDocumentId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-instructions-document-id"),
            renderHTML: (attrs) => ({ "data-instructions-document-id": attrs.instructionsDocumentId })
         },
         deliverableContent: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-deliverable-content"),
            renderHTML: (attrs) => ({ "data-deliverable-content": attrs.deliverableContent })
         },
         deliverableDocumentId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-deliverable-document-id"),
            renderHTML: (attrs) => ({ "data-deliverable-document-id": attrs.deliverableDocumentId })
         },
         sectionId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-section-id"),
            renderHTML: (attrs) => ({ "data-section-id": attrs.sectionId })
         },

         focusImage: {
            default: false,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-focus-image") === "true",
            renderHTML: (attrs) => ({ "data-focus-image": attrs.focusImage })
         },
         focusVideo: {
            default: false,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-focus-video") === "true",
            renderHTML: (attrs) => ({ "data-focus-video": attrs.focusVideo })
         },
         focusFile: {
            default: false,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-focus-file") === "true",

            renderHTML: (attrs) => ({ "data-focus-file": attrs.focusFile })
         },
         section_section_asset: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-section-section-asset"),
            renderHTML: (attrs) => ({ "data-section-section-asset": attrs.section_section_asset })
         },

         versionSpaceId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-version-space-id"),
            renderHTML: (attrs) => ({ "data-version-space-id": attrs.versionSpaceId })
         },
         attemptSpaceId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-attempt-space-id"),
            renderHTML: (attrs) => ({ "data-attempt-space-id": attrs.attemptSpaceId })
         },

         stepDocumentId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-step-document-id"),
            renderHTML: (attrs) => ({ "data-step-document-id": attrs.stepDocumentId })
         },
         sectionRun: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-section-run"),
            renderHTML: (attrs) => ({ "data-section-run": attrs.sectionRun })
         },
         motionSafeSrc: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-motion-safe-src"),
            renderHTML: (attrs) => ({ "data-motion-safe-src": attrs.motionSafeSrc })
         }
      };
   },

   parseHTML() {
      return [
         {
            tag: "div",
            getAttrs: (el) => {
               const res = (el as HTMLDivElement).getAttribute("data-asset-node-id")?.trim();
               ILog.v("AssetNode_parseHTML", { el, res });
               if (!res) {
                  return false;
               } else {
                  return null;
                  // return { id: res };
               }
            }
         }
      ];
   },

   renderHTML({ HTMLAttributes }) {
      ILog.v("AssetNode_renderHTML", { HTMLAttributes });
      return ["div", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
   },

   addNodeView() {
      return ReactNodeViewRenderer(AssetNodeContainer);
   },
   // addProseMirrorPlugins() {
   //    return [pasteHandler({})];
   // },
   onBeforeCreate() {
      ILog.v("AssetNode_onBeforeCreate");
   }
});

type PasteHandlerOptions = {
   // editor: ProseMirrorEditor;
   // schema: Schema;
};

export function pasteHandler(options: PasteHandlerOptions): Plugin {
   const {} = options;

   return new Plugin({
      key: new PluginKey("handleAssetNodePaste"),
      props: {
         handlePaste: (view, event, slice) => {
            ILog.v("handleAssetNoPaste_handlePaste", { view, event, slice });
            return false;
         }
      }
   });
}
// export const AssetNode = Node.create({
//    name: "AssetNode",
//    group: "block",
//    // content: "inline*",
//    draggable: true,
//    selectable: true,
//    isolating: true,
//    allowGapCursor: true,

//    addAttributes() {
//       return defaultNodeAttributes;
//    },
//    parseHTML() {
//       return [
//          {
//             tag: "div[data-type=draggable-item]"
//          }
//       ];
//    },

//    renderHTML({ HTMLAttributes }) {
//       return ["div", mergeAttributes(HTMLAttributes, { "data-type": "draggable-item" }), 0];
//    },
//    addNodeView() {
//       return ReactNodeViewRenderer(AssetNodeContainer);
//    }
// });
