import ILog from "app/_lib/global/Log";
import { WS_NAMESPACED } from "app/_services/ws/WSNamespaced";
import { socketResponse } from "app/_services/ws/socketEmitAsPromise";
import { PA } from "app/_types/PATypes";
import { v4 } from "uuid";
import { handleRTKError, handleRTKTags } from "../../../_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "../../../_services/redux/api/apiService";

export const apiWS = apiService.injectEndpoints({
   endpoints: (builder) => ({
      emitWSMessage: builder.mutation<
         null | any,
         {
            token?: string | undefined;
            role?: PA.RoleQueries | undefined;
            message: Record<string, any>;
         }
      >({
         extraOptions: {
            maxRetries: 3
         },

         queryFn: async (arg, api, extraOptions, baseQuery) => {
            try {
               if (!arg.role) throw new Error(`apiWSAuthenticated no token or roleId found ${arg.role} `);
               const requestId = v4();
               const ws = await WS_NAMESPACED({ roleQuery: arg.role, requestId, dispatch: api.dispatch });
               const client = ws?.client;
               if (!client) throw new Error("No client found");

               ILog.v("emitWSMessage", { ws, client, requestId });
               const res = await socketResponse<any | null, Record<string, any>>(client, arg.message);
               ILog.v("emitWSMessage", { res });
               ws.removeRequestId(requestId);

               return { data: res };
            } catch (e) {
               return handleRTKError({ error: e, endpoint: "emitWSMessage" });
            }
         },

         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query
            })
      })
   }),
   overrideExisting: true
});
