"use client";

import { Node, Editor as ProseMirrorEditor, mergeAttributes } from "@tiptap/core";
import { Fragment, Node as NodeModel, Schema, Slice } from "@tiptap/pm/model";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { ReactNodeViewRenderer } from "@tiptap/react";
import ILog from "app/_lib/global/Log";
import { ActivityPrefix } from "app/_types/Schema";
import dynamic from "next/dynamic";
import { isPotluckDeliverableTagURL } from "../../_helpers/handleRegex";
import { validateSpecNodeAttributes } from "../../_helpers/nodeAttributes";
import { RegexApi } from "../../_services/RegexApi";
import { ActivitySpecNodeAttributesRuntime } from "../nodes/defaultNodeAttributes";

const ActivitySpec = dynamic(() => import("./ActivitySpec"), { ssr: false });

export const ActivitySpecNode = Node.create({
   name: "ActivitySpec",
   group: "block",
   // content: "block*",
   // content: "inline*",
   draggable: true,
   selectable: true,
   isolating: true,
   allowGapCursor: true,

   addAttributes() {
      return {
         activitySpecId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-activity-spec-id"),
            renderHTML: (attrs) => ({ "data-activity-spec-id": attrs.activitySpecId })
         },

         campaignId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-campaign-id"),
            renderHTML: (attrs) => ({ "data-campaign-id": attrs.campaignId })
         },
         teamId: { default: undefined, parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-team-id"), renderHTML: (attrs) => ({ "data-team-id": attrs.teamId }) },
         templateId: { default: undefined, parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-template-id"), renderHTML: (attrs) => ({ "data-template-id": attrs.templateId }) },
         attemptId: { default: undefined, parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-attempt-id"), renderHTML: (attrs) => ({ "data-attempt-id": attrs.attemptId }) },
         editionId: { default: undefined, parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-edition-id"), renderHTML: (attrs) => ({ "data-edition-id": attrs.editionId }) },
         activityPrefix: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-activity-prefix"),
            renderHTML: (attrs) => ({ "data-activity-prefix": attrs.activityPrefix })
         },
         specType: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-spec-type"),
            renderHTML: (attrs) => ({ "data-spec-type": attrs.specType })
         },

         focusDeliverableTag: {
            default: false,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-focus-deliverable-tag") === "true",
            renderHTML: (attrs) => ({ "data-focus-deliverable-tag": attrs.focusDeliverableTag })
         },
         instructionsContent: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-instructions-content"),
            renderHTML: (attrs) => ({ "data-instructions-content": attrs.instructionsContent })
         },
         instructionsDocumentId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-instructions-document-id"),
            renderHTML: (attrs) => ({ "data-instructions-document-id": attrs.instructionsDocumentId })
         },
         deliverableContent: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-deliverable-content"),
            renderHTML: (attrs) => ({ "data-deliverable-content": attrs.deliverableContent })
         },
         deliverableDocumentId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-deliverable-document-id"),
            renderHTML: (attrs) => ({ "data-deliverable-document-id": attrs.deliverableDocumentId })
         },
         sectionId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-section-id"),
            renderHTML: (attrs) => ({ "data-section-id": attrs.sectionId })
         },

         focusImage: {
            default: false,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-focus-image") === "true",
            renderHTML: (attrs) => ({ "data-focus-image": attrs.focusImage })
         },
         focusVideo: {
            default: false,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-focus-video") === "true",
            renderHTML: (attrs) => ({ "data-focus-video": attrs.focusVideo })
         },
         focusFile: {
            default: false,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-focus-file") === "true",

            renderHTML: (attrs) => ({ "data-focus-file": attrs.focusFile })
         },
         section_section_asset: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-section-section-asset"),
            renderHTML: (attrs) => ({ "data-section-section-asset": attrs.section_section_asset })
         },

         versionSpaceId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-version-space-id"),
            renderHTML: (attrs) => ({ "data-version-space-id": attrs.versionSpaceId })
         },
         attemptSpaceId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-attempt-space-id"),
            renderHTML: (attrs) => ({ "data-attempt-space-id": attrs.attemptSpaceId })
         },

         stepDocumentId: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-step-document-id"),
            renderHTML: (attrs) => ({ "data-step-document-id": attrs.stepDocumentId })
         },
         sectionRun: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-section-run"),
            renderHTML: (attrs) => ({ "data-section-run": attrs.sectionRun })
         },
         motionSafeSrc: {
            default: undefined,
            parseHTML: (el) => (el as HTMLDivElement).getAttribute("data-motion-safe-src"),
            renderHTML: (attrs) => ({ "data-motion-safe-src": attrs.motionSafeSrc })
         }
      };
   },

   parseHTML() {
      return [
         {
            tag: "div",
            getAttrs: (el) => {
               const isASN = (el as HTMLDivElement).getAttribute("data-activity-spec-id")?.trim();
               ILog.v("SpecNode_parseHTML", { el, isASN });
               if (!isASN) {
                  return false;
               } else {
                  return null;
                  // return {
                  //    activitySpecId: isASN,
                  //    activityPrefix: (el as HTMLDivElement).getAttribute("data-activity-prefix") as ActivityPrefix,
                  //    attemptId: (el as HTMLDivElement).getAttribute("data-attempt-id") || undefined,
                  //    attemptSpaceId: (el as HTMLDivElement).getAttribute("data-attempt-space-id") || undefined,
                  //    campaignId: (el as HTMLDivElement).getAttribute("data-campaign-id") as ActivitySpecNodeAttributesRuntime["campaignId"],
                  //    deliverableContent: (el as HTMLDivElement).getAttribute("data-deliverable-content") as unknown as ActivitySpecNodeAttributesRuntime["deliverableContent"],
                  //    deliverableDocumentId: (el as HTMLDivElement).getAttribute("data-deliverable-document-id") as unknown as ActivitySpecNodeAttributesRuntime["deliverableDocumentId"],
                  //    editionId: (el as HTMLDivElement).getAttribute("data-edition-id") || undefined,
                  //    focusDeliverableTag: (el as HTMLDivElement).getAttribute("data-focus-deliverable-tag") === "true",
                  //    instructionsContent: (el as HTMLDivElement).getAttribute("data-instructions-content") as unknown as ActivitySpecNodeAttributesRuntime["instructionsContent"],
                  //    instructionsDocumentId: (el as HTMLDivElement).getAttribute("data-instructions-document-id") as unknown as ActivitySpecNodeAttributesRuntime["instructionsDocumentId"],
                  //    nodeType: "ActivitySpec",
                  //    sectionId: (el as HTMLDivElement).getAttribute("data-section-id") as ActivitySpecNodeAttributesRuntime["sectionId"],
                  //    sectionRun: Number((el as HTMLDivElement).getAttribute("data-section-run")),
                  //    specType: (el as HTMLDivElement).getAttribute("data-spec-type") as ActivitySpecNodeAttributesRuntime["specType"],
                  //    stepDocumentId: (el as HTMLDivElement).getAttribute("data-step-document-id") || undefined,
                  //    teamId: (el as HTMLDivElement).getAttribute("data-team-id") as ActivitySpecNodeAttributesRuntime["teamId"],
                  //    templateId: (el as HTMLDivElement).getAttribute("data-template-id") as ActivitySpecNodeAttributesRuntime["templateId"],
                  //    versionSpaceId: (el as HTMLDivElement).getAttribute("data-version-space-id") as ActivitySpecNodeAttributesRuntime["versionSpaceId"]
                  // } satisfies ActivitySpecNodeAttributesRuntime;
               }
            }
         }
      ];
   },

   renderHTML({ HTMLAttributes }) {
      const bool = !!HTMLAttributes["data-activity-spec-id"];
      ILog.v("SpecNode_renderHTML", { HTMLAttributes, bool, options: this.options.HTMLAttributes });
      return ["div", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
   },

   addNodeView() {
      return ReactNodeViewRenderer(ActivitySpec);
   },
   addProseMirrorPlugins() {
      return [pasteHandler({ editor: this.editor, schema: this.editor.schema })];
   }

   // renderText({ node }) {
   //    const {
   //       id,
   //       activityPrefix,
   //       attemptId,
   //       attemptSpaceId,
   //       campaignId,
   //       deliverableContent,
   //       deliverableDocumentId,
   //       editionId,
   //       focusDeliverableTag,
   //       instructionsContent,
   //       instructionsDocumentId,
   //       nodeType,
   //       sectionId,
   //       sectionRun,
   //       specType,
   //       stepDocumentId,
   //       teamId,
   //       templateId,
   //       versionSpaceId
   //    } = node.attrs as ActivitySpecNodeAttributesRuntime;
   //    const query = {
   //       id,
   //       nodeType: "ActivitySpec",
   //       activityPrefix,
   //       specType,
   //       focusDeliverableTag: true,
   //       campaignId,
   //       teamId,
   //       attemptId: attemptId || null,
   //       templateId,
   //       sectionId,
   //       sectionRun,
   //       instructionsDocumentId,
   //       deliverableDocumentId,
   //       attemptSpaceId: null,
   //       versionSpaceId: versionSpaceId,
   //       editionId: null
   //    } satisfies DeliverableTagAttributes;
   //    const params = new URLSearchParams();

   //    Object.entries(query).forEach(([key, value]) => {
   //       // params.append(key, typeof value === "string" ? value : JSON.stringify(value));
   //       params.append(key, value as string);
   //    });
   //    ILog.v("ActivitySpecNode_renderText", { query, params });
   //    return `https://www.potluckaction.com/embed?${params.toString()}`;
   // }

   // addProseMirrorPlugins() {
   //    return [
   //       new Plugin({
   //          props: {
   //             handleDOMEvents: {
   //                // prevent dragging nodes out of the figure
   //                dragstart: (view, event) => {
   //                   if (!event.target) {
   //                      return false;
   //                   }

   //                   const pos = view.posAtDOM(event.target as HTMLElement, 0);
   //                   const $pos = view.state.doc.resolve(pos);

   //                   if ($pos.parent.type === this.type) {
   //                      // event.preventDefault();
   //                      return false;
   //                   }
   //                   return true;
   //                }
   //             }
   //          }
   //       })
   //    ];
   // }
});

type PasteHandlerOptions = {
   editor: ProseMirrorEditor;
   schema: Schema;
};

export function pasteHandler(options: PasteHandlerOptions): Plugin {
   const { schema, editor } = options;

   return new Plugin({
      key: new PluginKey("handleDeliverableTagPaste"),
      props: {
         // handleDrop(view, event, slice, moved) {
         //    ILog.v("handleDeliverableTagPaste_handleDrop", { view, event, slice, moved });
         //    return false;
         // },
         // clipboardTextSerializer: (slice) => {
         //    let text = "SERIALIZED";
         //    ILog.v("clipboardTextSerializer_handleDeliverableTagPaste", { slice, text });

         //    return text;
         // },
         handlePaste: (view, event, slice) => {
            let shouldHandle = false;
            let transformedFragment = Fragment.empty;
            ILog.v("handleDeliverableTagPaste", { view, event, slice });
            slice.content.forEach((node) => {
               if (node.isTextblock) {
                  let { fragment: newNodes, hasURLFragment } = transformTextBlockNode(node, schema, editor);
                  transformedFragment = transformedFragment.append(newNodes);
                  if (hasURLFragment) {
                     shouldHandle = true;
                  }
                  ILog.v("handleDeliverableTagPaste_handlePaste1", { transformedFragment });
               } else if (node.isText) {
                  const { fragment, isURLFragment } = transformTextNode(node, schema, editor);
                  transformedFragment = transformedFragment.append(fragment);
                  ILog.v("handleDeliverableTagPaste_handlePaste2", { transformedFragment });
                  if (isURLFragment) {
                     shouldHandle = true;
                  }
               } else {
                  transformedFragment = transformedFragment.append(Fragment.from(node));
                  ILog.v("handleDeliverableTagPaste_handlePaste3", { transformedFragment });
               }
            });
            if (shouldHandle) {
               ILog.v("handleDeliverableTagPaste_handlePaste4", { shouldHandle, transformedFragment, tr: view.state.tr });
               let openStart = 0;
               let openEnd = 0;

               let newSlice = new Slice(transformedFragment, openStart, openEnd);

               let transaction = view.state.tr.replaceSelection(newSlice);
               view.dispatch(transaction);
               ILog.v("handleDeliverableTagPaste_handlePaste5", { newSlice, transaction });
               return true;
            } else {
               if (view.state.selection.empty) {
               }
               const anchor = view.state.selection.anchor;
               // const pos = view.state.doc.resolve(slice.openStart);
               // const t1 = view.state.tr.replaceSelection(slice);
               // return view.dispatch(t1);

               // const _selection = TextSelection.create(view.state.doc, anchor, anchor + slice.content.size);
               // view.state.tr.setSelection(_selection);
               // view.dispatch(view.state.tr);
               // view.state.tr.setSelection(view.state.tr.selection);
               ILog.v("handleDeliverableTagPaste_handlePaste6", { anchor, tr: view.state.tr });
               // options.editor.commands.setTextSelection({ from: anchor, to: anchor + slice.content.size });
               // return view.dispatch(view.state.tr.setSelection(view.state.tr.selection));
               // return view.dispatch(transaction);
               return false;
            }
         }
      }
   });
}

function transformTextBlockNode(node: NodeModel, schema: Schema, editor: ProseMirrorEditor): { fragment: Fragment; hasURLFragment: boolean } {
   let newFragment = Fragment.empty;
   let hasURLFragment = false;

   node.forEach((childNode: NodeModel) => {
      if (childNode.isText) {
         const { fragment, isURLFragment } = transformTextNode(childNode, schema, editor);
         if (isURLFragment) {
            hasURLFragment = true;
         }
         newFragment = newFragment.append(fragment);
      } else {
         newFragment = newFragment.append(Fragment.from(childNode));
      }
   });
   return { fragment: newFragment, hasURLFragment };
}

function transformTextNode(node: NodeModel, schema: Schema, editor: ProseMirrorEditor): { fragment: Fragment; isURLFragment: boolean } {
   let text = node.text;
   let isURLFragment = false;
   const urls = RegexApi.matchUrlsFromText(text || "");

   const potluckURLs = urls.filter((url) => {
      return isPotluckDeliverableTagURL(url);
   });

   // const editorIsEmpty = editor.state.doc.childCount === 1 && editor.state.doc.firstChild!.childCount === 0;
   let newFragment = Fragment.empty;
   // newFragment = newFragment.append(Fragment.from(schema.node("paragraph")));
   let lastIndex = 0;

   potluckURLs.forEach((url) => {
      ILog.v("transformTextNode", { text, urls, potluckURLs });
      const urlIndex = text!.indexOf(url);
      const part = text!.substring(lastIndex, urlIndex);

      if (part) {
         newFragment = newFragment.append(Fragment.from(schema.text(part)));
      }

      const urlParams = new URLSearchParams(url.split("?")[1]);
      const activitySpecId = urlParams.get("activitySpecId");
      const attemptId = urlParams.get("attemptId");
      const attemptSpaceId = urlParams.get("attemptSpaceId");
      const editionId = urlParams.get("editionId");
      let params = {
         teamId: urlParams.get("teamId") || undefined,
         activityPrefix: urlParams.get("activityPrefix") as ActivityPrefix,
         templateId: urlParams.get("templateId") || undefined,
         focusDeliverableTag: urlParams.get("focusDeliverableTag") === "true",
         sectionId: urlParams.get("sectionId") || undefined,
         sectionRun: Number(urlParams.get("sectionRun")),
         specType: urlParams.get("specType") as ActivitySpecNodeAttributesRuntime["specType"],
         campaignId: urlParams.get("campaignId") || undefined,
         stepDocumentId: urlParams.get("stepDocumentId") || undefined,
         deliverableDocumentId: urlParams.get("deliverableDocumentId") || undefined,
         instructionsDocumentId: urlParams.get("instructionsDocumentId") || undefined,
         nodeType: "ActivitySpec",
         versionSpaceId: urlParams.get("versionSpaceId") || undefined
      } satisfies Partial<ActivitySpecNodeAttributesRuntime>;
      ILog.v("transformTextNode_validate_params", { params });
      // const res = validateParams<ActivitySpecNodeAttributesRuntime>(params);
      const customNodeAttrs: ActivitySpecNodeAttributesRuntime = {
         ...params,
         activitySpecId,
         attemptId: attemptId !== "undefined" && attemptId !== null && attemptId !== "null" ? attemptId : undefined,
         attemptSpaceId: attemptSpaceId !== "undefined" && attemptSpaceId !== null && attemptSpaceId !== "null" ? attemptSpaceId : undefined,
         editionId: editionId !== "undefined" && editionId !== null && editionId !== "null" ? editionId : undefined,
         deliverableContent: undefined,
         instructionsContent: undefined
      } as ActivitySpecNodeAttributesRuntime;
      const finalAttrs = validateSpecNodeAttributes({ attributes: customNodeAttrs });
      if (finalAttrs) {
         isURLFragment = true;
      }

      // for (const [key, value] of urlParams.entries()) {
      //    if(key in customNodeAttrs){
      //       const IKey = key
      //       customNodeAttrs[IKey] = value || undefined;
      //    }
      // }
      const customNode = schema.nodes.ActivitySpec.create(finalAttrs);

      newFragment = newFragment.append(Fragment.from(customNode));

      newFragment = newFragment.append(Fragment.from(schema.node("paragraph")));
      // if (editorIsEmpty) {
      // }

      ILog.v("transformTextNode", { customNodeAttrs, urlParams, customNode, newFragment, isURLFragment });
      lastIndex = urlIndex + url.length;
   });

   if (lastIndex < text!.length) {
      newFragment = newFragment.append(Fragment.from(schema.text(text!.substring(lastIndex))));
   }

   return { fragment: newFragment, isURLFragment };
}
