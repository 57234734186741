import { Extension } from "@tiptap/react";
import ILog from "app/_lib/global/Log";

// const nodeDeletionPlugin = new Plugin({
//    key: new PluginKey("preventNodeDeletion"),
//    filterTransaction: (transaction, state) => {
//       let allowTransaction = true;
//       const replaceSteps: any[] = [];
//       // allow the transaction if it's an intentional deletion
//       if (transaction.getMeta("intentionalDeletion")) {
//          return allowTransaction;
//       }
//       transaction.steps.forEach((step: any, index) => {
//          if (step.jsonID === "replace") {
//             const nodes = step.slice.content.content;
//             if (nodes.length === 1) {
//                const node = nodes[0];
//                // if it's updating ActivitySpec, don't stop transaction
//                if (node.type.name === "ActivitySpec") {
//                   return;
//                }
//             }
//             // filter delete or replace nodes actions to prevent ActivitySpec button being deleted by keyboard
//             replaceSteps.push(index);
//          }
//       });
//       replaceSteps.forEach((index) => {
//          const map = transaction.mapping.maps[index] as any;
//          const oldStart = map.ranges[0];
//          const oldEnd = map.ranges[0] + map.ranges[1];
//          state.doc.nodesBetween(oldStart, oldEnd, (node) => {
//             if (node.type.name === "ActivitySpec") {
//                allowTransaction = false;
//             }
//          });
//       });
//       // transaction.steps.forEach((step: any) => {
//       //    if (step.jsonID === "replace") {
//       //       const nodes = step.slice.content.content;
//       //       nodes.forEach((node: any) => {
//       //          if (node.type.name === "ActivitySpec") {
//       //             allowTransaction = false;
//       //          }
//       //       });
//       //    }
//       // });
//       const meta = transaction.getMeta("y-sync");
//       ILog.v("preventNodeDeletionPlugin", { meta, transaction, allowTransaction });
//       return true;
//    }
// });

export const NodeDeletionHandlerExtension = new Extension({
   name: "nodeDeletionHandler",
   // addProseMirrorPlugins() {
   //    return [nodeDeletionPlugin];
   // },
   // addStorage() {
   //    return {
   //       droppedSectionAssetIds: new Set<string>()
   //    };
   // },
   addStorage() {
      return {
         droppedSectionAssetIds: new Set<string>(),
         droppedSectionIds: new Set<string>()
      };
   },

   onTransaction(props) {
      const { transaction, editor } = props;
      if (!transaction.steps.length) return true;
      // this.storage.droppedSectionAssetIds = ["TEST"];
      ILog.v("NodeDeletionHandlerExtension_onTransaction", { storage: this.storage, transaction, before: transaction.before });
      transaction.before.descendants((node) => {
         if (node.type.name === "AssetNode") {
            const saId = node.attrs.section_section_asset;
            const sId = node.attrs.sectionId;
            ILog.v("before transaction node_ADD", { saId, sId });
            if (saId) this.storage.droppedSectionAssetIds.add(saId);
            if (sId) this.storage.droppedSectionIds.add(sId);
         }
      });

      transaction.doc.descendants((node) => {
         if (node.type.name === "AssetNode") {
            const saId = node.attrs.section_section_asset;
            const sId = node.attrs.sectionId;
            ILog.v("After transaction node_DELETE", { saId, sId });
            this.storage.droppedSectionAssetIds.delete(node.attrs.section_section_asset);
            this.storage.droppedSectionIds.delete(node.attrs.sectionId);
         }
      });

      if (this.storage.droppedSectionAssetIds.size > 0) {
         ILog.v("NodeDeletionHandlerExtension_onTransaction_droppedSectionAssetIds", { droppedSectionAssetIds: this.storage.droppedSectionAssetIds });
      }
      if (this.storage.droppedSectionIds.size > 0) {
         ILog.v("NodeDeletionHandlerExtension_onTransaction_droppedSectionIds", { droppedSectionIds: this.storage.droppedSectionIds });
      }

      ILog.v("NodeDeletionHandlerExtension_onTransaction_FINAL", { storage: this.storage });
      return true;
   },

   onDestroy() {
      const { droppedSectionAssetIds, droppedSectionIds } = this.storage;
      ILog.v("NodeDeletionHandlerExtension_onDestroy", { droppedSectionAssetIds, droppedSectionIds });
   }
});
