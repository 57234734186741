"use client";

import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import dynamic from "next/dynamic";

const Comparison = dynamic(() => import("./Comparison"), { ssr: false });

export const ComparisonNode = Node.create({
   name: "Comparison",
   group: "block",
   // content: "block*",
   // content: "inline*",
   draggable: false,
   selectable: false,
   isolating: true,
   allowGapCursor: true,

   addAttributes() {
      return {
         id: {
            default: undefined
         }
      };
   },

   parseHTML() {
      return [
         {
            tag: "comparison" //"div[data-type=comparison]"
         }
      ];
   },

   renderHTML({ HTMLAttributes }) {
      return ["comparison", mergeAttributes(HTMLAttributes, { "data-type": "comparison" })];
   },

   addNodeView() {
      return ReactNodeViewRenderer(Comparison);
   }
   // addProseMirrorPlugins() {
   //    return [
   //       new Plugin({
   //          props: {
   //             handleDOMEvents: {
   //                // prevent dragging nodes out of the figure
   //                dragstart: (view, event) => {
   //                   if (!event.target) {
   //                      return false;
   //                   }

   //                   const pos = view.posAtDOM(event.target as HTMLElement, 0);
   //                   const $pos = view.state.doc.resolve(pos);

   //                   if ($pos.parent.type === this.type) {
   //                      // event.preventDefault();
   //                      return false;
   //                   }
   //                   return true;
   //                }
   //             }
   //          }
   //       })
   //    ];
   // }
});
