import { Extension } from "@tiptap/core";
import { Transaction } from "@tiptap/pm/state";
import { Editor } from "@tiptap/react";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import { Node as ProsemirrorNode } from "prosemirror-model";
import { EditorState, Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { useEffect, useRef } from "react";
import { apiChat } from "../../_api/chat";
import { FocusItemMarkAttributes } from "../nodes/defaultNodeAttributes";
declare module "@tiptap/core" {
   interface Commands<ReturnType> {
      search: {
         /**
          * @description Set search term in extension.
          */
         setSearchTerm: (searchTerm: string) => ReturnType;
         /**
          * @description Set replace term in extension.
          */
         setReplaceTerm: (replaceTerm: string) => ReturnType;
         /**
          * @description Replace first instance of search result with given replace term.
          */
         replaceNext: () => ReturnType;
         /**
          * @description Replace all instances of search result with given replace term.
          */
         replaceAll: () => ReturnType;
         goToPreviousResult: () => ReturnType;
         goToNextResult: () => ReturnType;
         markNext: ({ attrs }: { attrs: FocusItemMarkAttributes }) => ReturnType;
         markAll: ({ attrs }: { attrs: FocusItemMarkAttributes }) => ReturnType;
      };
   }
}

interface Result {
   from: number;
   to: number;
}

interface SearchOptions {
   searchTerm: string;
   replaceTerm: string;
   results: Result[];
   searchResultClass: string;
   caseSensitive: boolean;
   disableRegex: boolean;
}

interface TextNodesWithPosition {
   text: string;
   pos: number;
}
type DispatchType = ((args?: any) => any) | undefined;
const updateView = (state: EditorState, dispatch: any) => dispatch(state.tr);

const regex = (s: string, disableRegex: boolean, caseSensitive: boolean): RegExp => {
   return RegExp(disableRegex ? s.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&") : s, caseSensitive ? "gu" : "gui");
};

function processSearches({ doc, searchTerm, searchResultClass }: { doc: ProsemirrorNode; searchTerm: RegExp; searchResultClass: string }): { decorationsToReturn: DecorationSet; results: Result[] } {
   const decorations: Decoration[] = [];
   let textNodesWithPosition: TextNodesWithPosition[] = [];
   const results: Result[] = [];

   let index = 0;

   if (!searchTerm) return { decorationsToReturn: DecorationSet.empty, results: [] };

   doc?.descendants((node, pos) => {
      if (node.isText) {
         if (textNodesWithPosition[index]) {
            textNodesWithPosition[index] = {
               text: textNodesWithPosition[index].text + node.text,
               pos: textNodesWithPosition[index].pos
            };
         } else {
            textNodesWithPosition[index] = {
               text: `${node.text}`,
               pos
            };
         }
      } else {
         index += 1;
      }
   });

   textNodesWithPosition = textNodesWithPosition.filter(Boolean);

   for (let i = 0; i < textNodesWithPosition.length; i += 1) {
      const { text, pos } = textNodesWithPosition[i];

      const matches = [...text.matchAll(searchTerm)];

      for (let j = 0; j < matches.length; j += 1) {
         const m = matches[j];

         if (m[0] === "") break;

         if (m.index !== undefined) {
            results.push({
               from: pos + m.index,
               to: pos + m.index + m[0].length
            });
         }
      }
   }

   for (let i = 0; i < results.length; i += 1) {
      const r = results[i];
      decorations.push(Decoration.inline(r.from, r.to, { class: searchResultClass }));
   }

   return {
      decorationsToReturn: DecorationSet.create(doc, decorations),
      results
   };
}

const rebaseNextResult = ({ replaceTerm, index, lastOffset, results }: { replaceTerm: string; index: number; lastOffset: number; results: Result[] }): [number, Result[]] | null => {
   const nextIndex = index + 1;

   if (!results[nextIndex]) return null;

   const { from: currentFrom, to: currentTo } = results[index];

   const offset = currentTo - currentFrom - replaceTerm.length + lastOffset;

   const { from, to } = results[nextIndex];

   results[nextIndex] = {
      to: to - offset,
      from: from - offset
   };

   return [offset, results];
};

const locateNextResult = ({ searchTerm, index, lastOffset, results }: { searchTerm: string; index: number; lastOffset: number; results: Result[] }): [number, Result[]] | null => {
   const nextIndex = index + 1;

   if (!results[nextIndex]) return null;

   const { from: currentFrom, to: currentTo } = results[index];

   const offset = currentTo - currentFrom - searchTerm.length + lastOffset;

   const { from, to } = results[nextIndex];

   results[nextIndex] = {
      to: to - offset,
      from: from - offset
   };

   return [offset, results];
};

const replace = ({ replaceTerm, results, state, dispatch }: { replaceTerm: string; results: Result[]; state: EditorState; dispatch: DispatchType }) => {
   const firstResult = results[0];

   if (!firstResult) return;

   const { from, to } = results[0];

   if (dispatch) dispatch(state.tr.insertText(replaceTerm, from, to));
};

const mark = ({
   replaceTerm,
   results,
   state,
   dispatch,
   set,

   tr,
   attrs
}: {
   replaceTerm: string;
   results: Result[];
   state: EditorState;
   dispatch: DispatchType;
   set: boolean;

   tr: Transaction;
   attrs: FocusItemMarkAttributes;
}) => {
   const firstResult = results[0];

   if (!firstResult) return;

   const { from, to } = results[0];
   tr.addMark(from, to, state.schema.marks.focusItem.create({ ...attrs }));
   if (dispatch) dispatch(tr);
};

const markAll = ({
   state,
   searchTerm,
   results,
   tr,
   dispatch,
   attrs
}: {
   state: EditorState;
   searchTerm: string;
   results: Result[];
   tr: Transaction;
   dispatch: DispatchType;
   attrs: FocusItemMarkAttributes;
}) => {
   let offset = 0;

   let ourResults = results.slice();

   if (!ourResults.length) return;

   for (let i = 0; i < ourResults.length; i += 1) {
      const { from, to } = ourResults[i];

      tr.addMark(from, to, state.schema.marks.focusItem.create({ ...attrs }));

      const rebaseNextResultResponse = locateNextResult({ searchTerm, index: i, lastOffset: offset, results: ourResults });

      if (rebaseNextResultResponse) {
         offset = rebaseNextResultResponse[0];
         ourResults = rebaseNextResultResponse[1];
      }
   }

   dispatch && dispatch(tr);
};

const replaceAll = ({ replaceTerm, results, tr, dispatch }: { replaceTerm: string; results: Result[]; tr: Transaction; dispatch: DispatchType }) => {
   let offset = 0;

   let ourResults = results.slice();

   if (!ourResults.length) return;

   for (let i = 0; i < ourResults.length; i += 1) {
      const { from, to } = ourResults[i];

      tr.insertText(replaceTerm, from, to);

      const rebaseNextResultResponse = rebaseNextResult({ replaceTerm, index: i, lastOffset: offset, results: ourResults });
      if (rebaseNextResultResponse) {
         offset = rebaseNextResultResponse[0];
         ourResults = rebaseNextResultResponse[1];
      }
   }

   dispatch && dispatch(tr);
};

export const SearchPlugin = Extension.create<SearchOptions>({
   name: "search",
   // addOptions() {
   //    return { searchTerm: "", replaceTerm: "", results: [], searchResultClass: "search-result", caseSensitive: false, disableRegex: false };
   // },

   // TODO: defaultOptions is deprecated. Use addOptions instead.
   defaultOptions: {
      searchTerm: "",
      replaceTerm: "",
      results: [],
      searchResultClass: "search-result",
      caseSensitive: false,
      disableRegex: false
   },
   storage: {
      searchTerm: null,
      replaceTerm: "",
      searchIndex: -1,
      results: [],
      searchResultClass: "search-result",
      caseSensitive: false,
      disableRegex: false
   },

   addCommands() {
      return {
         setSearchTerm:
            (searchTerm: string) =>
            ({ state, dispatch }) => {
               this.storage.searchTerm = searchTerm;
               this.storage.results = [];

               this.editor.commands.focus();
               if (dispatch) dispatch(state.tr);
               updateView(state, dispatch);

               return false;
            },
         setReplaceTerm:
            (replaceTerm: string) =>
            ({ state, dispatch }) => {
               this.storage.replaceTerm = replaceTerm;
               this.storage.results = [];

               updateView(state, dispatch);

               return false;
            },
         replaceNext:
            () =>
            ({ state, dispatch, view, tr, ...others }) => {
               const { replaceTerm, results } = this.storage;

               replace({ replaceTerm, results, state, dispatch });

               this.storage.results.shift();

               updateView(state, dispatch);

               return false;
            },
         replaceAll:
            () =>
            ({ state, tr, dispatch }) => {
               const { replaceTerm, results } = this.storage;

               replaceAll({ replaceTerm, results, tr, dispatch });

               this.storage.results = [];

               updateView(state, dispatch);

               return false;
            },
         markNext:
            ({ attrs }) =>
            ({ state, dispatch, view, tr, ...others }) => {
               const { replaceTerm, results, searchTerm } = this.storage;

               mark({
                  replaceTerm,
                  results,
                  state,
                  dispatch,
                  attrs,

                  set: true,
                  tr
               });

               this.storage.results.shift();

               updateView(state, dispatch);

               return false;
            },
         markAll:
            ({ attrs }) =>
            ({ state, tr, dispatch }) => {
               const { replaceTerm, results, searchTerm } = this.storage;

               markAll({
                  searchTerm,
                  results,
                  tr,
                  dispatch,
                  attrs,

                  state
               });

               this.storage.results = [];

               updateView(state, dispatch);

               return false;
            },
         goToNextResult:
            () =>
            ({ state, tr, dispatch }) => {
               const { results, searchIndex } = this.storage;
               const index = searchIndex === undefined ? -1 : searchIndex;
               const nextIndex = index >= results.length - 1 ? 0 : index + 1;
               if (results[nextIndex]) this.editor.commands.setTextSelection(results[nextIndex]);
               this.storage.searchIndex = nextIndex;
               return true;
            },
         goToPreviousResult:
            () =>
            ({ state, tr, dispatch }) => {
               const { results, searchIndex } = this.storage;
               const index = searchIndex === undefined ? results.length - 1 : searchIndex;
               const prevIndex = index === 0 ? results.length - 1 : index - 1;
               if (results && results[prevIndex]) this.editor.commands.setTextSelection(results[prevIndex]);
               this.storage.searchIndex = prevIndex;
               return false;
            }
      };
   },

   addKeyboardShortcuts() {
      return {
         "Mod-f": () => {
            this.editor.commands.setSearchTerm("");
            return true;
         },
         "Control-,": () => {
            this.editor.chain().focus().goToNextResult();
            return true;
         },
         "Control-.": () => {
            this.editor.chain().focus().goToPreviousResult();
            return true;
         },
         "Control-;": () => {
            this.editor.commands.replaceNext();
            return true;
         },
         "Control-'": () => {
            this.editor.chain().focus().replaceAll();
            return true;
         }
      };
   },

   addProseMirrorPlugins() {
      const extensionThis = this;

      return [
         new Plugin({
            key: new PluginKey("search"),
            state: {
               init() {
                  return DecorationSet.empty;
               },
               apply(tr, value, oldState, newState) {
                  const { doc, docChanged } = tr;
                  const { searchTerm, disableRegex, caseSensitive } = extensionThis.storage;
                  const { searchResultClass } = extensionThis.options;
                  if (docChanged) {
                     value.map(tr.mapping, tr.doc);
                  }

                  if (docChanged || searchTerm) {
                     const { decorationsToReturn, results } = processSearches({ doc, searchTerm: regex(searchTerm, disableRegex, caseSensitive), searchResultClass });

                     extensionThis.storage.results = results;

                     return decorationsToReturn;
                  }
                  return DecorationSet.empty;
               }
            },
            props: {
               decorations(state) {
                  return this.getState(state);
               }
            }
         })
      ];
   }
});

export const SearchBoxComponent = ({ editor, token }: { editor: Editor; token: string }) => {
   const searchRef = useRef<HTMLInputElement | null>(null);
   const { searchTerm } = editor.extensionStorage.search;
   const [createChat] = apiChat.useCreateChatMutation();
   useEffect(() => {
      if (searchRef.current && searchTerm !== null && searchTerm !== undefined) {
         searchRef.current.value = searchTerm || "";
         searchRef.current.focus();
      }
   }, [searchTerm]);

   return (
      <_DivUNSAFE
         className={
            /* clsx(`search-box`, {
            active: searchTerm !== undefined
         }) */ ""
         }
      >
         {/* <_DivUNSAFE className="flex w-full border-t border-b border-slate-300">
            <_DivUNSAFE className="flex flex-col w-full">
               <_DivUNSAFE className="flex items-center flex-1 w-full border-r border-slate-300">
                  <input
                     className="flex-1 h-8 text-sm placeholder-gray-500 border-0 focus:outline-hidden border-r-1 border-slate-200"
                     ref={searchRef}
                     type="text"
                     placeholder="Search..."
                     data-test-id="search-input"
                     onChange={(e) => {
                        editor.commands.setSearchTerm(e.target.value);
                     }}
                     onKeyDown={(e) => {
                        if (e.key === "Enter") editor.chain().focus().goToNextResult();
                     }}
                  />
               </_DivUNSAFE>

               <_DivUNSAFE className="flex items-center flex-1 w-full border-t border-r border-slate-300">
                  <input
                     className="flex-1 h-8 text-sm placeholder-gray-500 border-0 focus:outline-hidden border-r-1 border-slate-200"
                     data-test-id="replace-input"
                     type="text"
                     placeholder="Replace..."
                     onChange={(e) => {
                        editor.commands.setReplaceTerm(e.target.value);
                     }}
                     onKeyDown={(e) => {
                        if (e.key === "Enter") editor.commands.replaceNext();
                     }}
                  />
               </_DivUNSAFE>
            </_DivUNSAFE>

    
            <_DivUNSAFE className="flex flex-col w-36">
               <_Button
                  onButtonClick={() => {
                     editor.commands.replaceNext();
                  }}
                  className="h-8 px-4 text-sm bg-slate-200 hover:bg-slate-300 focus:outline-hidden focus:ring"
                  testId="search-replace-one"
               >
                  Replace One
               </_Button>
               <_Button
                  onButtonClick={() => {
                     editor.commands.replaceAll();
                  }}
                  className="h-8 px-4 text-sm border-t border-slate-300 bg-slate-200 hover:bg-slate-300 focus:outline-hidden focus:ring"
                  testId="search-replace-all"
               >
                  Replace All
               </_Button>
               <_Button
                  onButtonClick={() => {
                     createChat({
                        token: token,
                        role,
                        payload: {
                           status: "open",
                           chat_campaign: campaignId,
                           chat_document: editorDocumentId,
                           chat_chat_message: []
                        },
                        editor: toolbarEditor,

                        documentId: editorDocumentId,
                        onCreate(editor, chat) {
                             editor.commands.markNext({
                                attrs: {
                                   focusItemId: chat.id,
                                   type: "comment",
                                   tagId: "123",
                                   base64: undefined,
                                   href: undefined,
                                   title: undefined,
                                   personId: "",
                                   chatIds: []
                                }
                             });
                        }
                     });

                  
                  }}
                  className="h-8 px-4 text-sm bg-slate-200 hover:bg-slate-300 focus:outline-hidden focus:ring"
                  testId="search-replace-one"
               >
                  Mark One
               </_Button>
               <_Button
                  onButtonClick={() => {
                     createChat({
                        token: token,
                        role,
                        payload: {
                           status: "open",
                           chat_campaign: campaignId,
                           chat_document: editorDocumentId,
                           chat_chat_message: []
                        },
                        editor: toolbarEditor,

                        documentId: editorDocumentId,
                        onCreate(editor, chat) {
                            editor.commands.markAll({
                               attrs: {
                                  focusItemId: chat.id,
                                  type: "comment",
                                  tagId: "123",
                                  base64: undefined,
                                  href: undefined,
                                  title: undefined,
                                  personId: "",
                                  chatIds: []
                               }
                            });
                        }
                     });

                   
                  }}
                  className="h-8 px-4 text-sm border-t border-slate-300 bg-slate-200 hover:bg-slate-300 focus:outline-hidden focus:ring"
                  testId="search-replace-all"
               >
                  Mark All
               </_Button>
            </_DivUNSAFE>
         </_DivUNSAFE> */}
      </_DivUNSAFE>
   );
};
