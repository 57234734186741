import { SuggestionProps } from "@tiptap/suggestion";
import Header from "app/_components_v2/data-display/Header";
import _Button from "app/_components_v2/inputs/_Button";
import _DivUNSAFE from "app/_components_v2/layout/_DivUNSAFE";
import _Stack from "app/_components_v2/layout/_Stack";
import ILog from "app/_lib/global/Log";
import { M } from "app/_types/Schema";
import { useParams } from "next/navigation";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { v4 } from "uuid";
import useAllCDURoleQueries from "../../../_hooks/useAllCDUQueries";
import { apiSections } from "../../_api/step";
import useActivityPrefix from "../../activities/_hooks/useActivityPrefix";
import { ActivitySpecNodeAttributesRuntime } from "../nodes/defaultNodeAttributes";

interface HydratedStep {
   step: M.Section;
   attrs: ActivitySpecNodeAttributesRuntime;
}
interface ISearchResults {
   controls: M.ActivityControl[];
   templates: M.ActivityVersion[];
   attempts: M.ActivityAttempt[];
   steps: HydratedStep[];
}

export const SearchContainer = forwardRef((props: SuggestionProps, ref) => {
   const campaignId = useParams().campaign_id as string;
   const teamId = useParams().team_id as string;
   const templateId = useParams().template_id as string;
   const attemptId = useParams().attempt_id as string | undefined;
   const versionSpaceId = useParams().template_space_id as string;
   const attemptSpaceId = useParams().attempt_space_id as string | undefined;
   const documentId = useParams().document_id as string;
   const { activityPrefix, activityType } = useActivityPrefix({ teamId, templateId, attemptId });
   const { connectionRole } = useAllCDURoleQueries({ campaignId });

   const [searchResults, setSearchResults] = useState<ISearchResults>({ controls: [], templates: [], attempts: [], steps: [] });
   const [filters, setFilters] = useState({
      activityType: "",
      activityPrefix: "",
      // is_step: false,
      // attempt_index: "",
      // pass_segment: "",
      version_status: "",
      attempt_status: "",
      system_status: ""
   });

   const _and = useMemo(() => {
      let _and = [{ section_activity_version: { _eq: templateId } }];
      //@ts-expect-error
      if (attemptId) _and.push({ section_activity_attempt: { _eq: attemptId } });

      return _and;
   }, [props.query, templateId, attemptId, campaignId]);

   const { data: steps } = apiSections.useGetManySectionQuery(
      {
         role: connectionRole,
         query: {
            filter: {
               _and
            },
            //@ts-expect-error
            fields: ["*,item.*"]
         }
      },
      { skip: !connectionRole }
   );

   useEffect(() => {
      if (steps && steps.length > 0 && activityPrefix) {
         setSearchResults({
            ...searchResults,
            steps: steps.map((step) => {
               return {
                  step: step,
                  attrs: {
                     activitySpecId: v4(),
                     sectionId: step.id,
                     teamId: step.section_activity_control as string,
                     templateId: step.section_activity_version as string,
                     attemptId: step.section_activity_attempt as string | undefined,
                     campaignId: campaignId,
                     editionId: undefined,
                     versionSpaceId,
                     attemptSpaceId,
                     stepDocumentId: undefined,
                     activityPrefix,
                     deliverableContent: undefined,
                     deliverableDocumentId: step.item?.section_document_edit_document as string,
                     focusDeliverableTag: false,
                     instructionsContent: undefined,
                     instructionsDocumentId: step.section_instructions_document as string,
                     sectionRun: step.section_run,
                     specType: activityType,
                     nodeType: "ActivitySpec"
                  } satisfies ActivitySpecNodeAttributesRuntime
               };
            })
         });
      }
   }, [steps]);

   useImperativeHandle(ref, () => ({
      onKeyDown: (event: KeyboardEvent) => {
         if (event.key === "Enter") {
            ILog.v("mentionSuggestion enterHandler", { props });
            const req = searchResults.steps?.[0];

            selectItem({ attrs: req.attrs });
            return true;
         }

         return false;
      }
   }));

   function selectItem({ attrs }: { attrs: ActivitySpecNodeAttributesRuntime }) {
      ILog.v("mentionSuggestion selectItem", { attrs });
      props.command(attrs);
   }

   const handleFilterChange = (newFilters: any) => {
      setFilters(newFilters);
   };
   return (
      <_DivUNSAFE id="search-menu" data-tippy-root className="flex flex-col gap-3 p-2 rounded-lg bg-slate-50 drop-shadow-lg">
         <SearchResults results={searchResults} selectItem={selectItem} />
      </_DivUNSAFE>
   );
});

SearchContainer.displayName = "SearchContainer";

interface SearchResultsProps {
   results: ISearchResults;
   selectItem({ attrs }: { attrs: ActivitySpecNodeAttributesRuntime }): void;
}
function SearchResults({ results, selectItem }: SearchResultsProps) {
   if (!results.steps) return null;
   return <SearchSection results={results.steps} title="Steps" selectItem={selectItem} />;
}
interface SearchSection {
   results: HydratedStep[];
   title: string;
   selectItem({ attrs }: { attrs: ActivitySpecNodeAttributesRuntime }): void;
}

function SearchSection({ results, title, selectItem }: SearchSection) {
   if (!results || !results.length) {
      return null;
   }

   return (
      <_Stack alignment={"start"}>
         <Header label={title} level="h2" />
         {results?.map((data: HydratedStep) => {
            return <SearchResult key={data.step.id} title={data?.step.section_title || ""} selectItem={selectItem} attrs={data.attrs} />;
         })}
      </_Stack>
   );
}

interface ISearchResult {
   title: string;
   selectItem({ attrs }: { attrs: ActivitySpecNodeAttributesRuntime }): void;
   attrs: ActivitySpecNodeAttributesRuntime;
}
function SearchResult({ title, selectItem, attrs }: ISearchResult) {
   if (!title) return null;
   return (
      <_Stack flexBehavior={"row"}>
         <_Button
            placement={"none"}
            testId={``}
            onButtonClick={() => {
               selectItem({ attrs });
            }}
         >
            {title}
         </_Button>
         <_Button
            placement={"none"}
            testId={``}
            onButtonClick={() => {
               selectItem({ attrs: { ...attrs, focusDeliverableTag: true } });
            }}
         >
            {"Deliverable"}
         </_Button>
      </_Stack>
   );
}

interface SearchFiltersProps {
   filters: {
      activityType: string;
      activityPrefix: string;
      // is_step: boolean;
      // attempt_index: string;
      // pass_segment: string;
      version_status: string;
      attempt_status: string;
      system_status: string;
   };
   onFilterChange: (filters: any) => void;
}
function SearchFilters({ filters, onFilterChange }: SearchFiltersProps) {
   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      onFilterChange({ ...filters, [name]: value });
   };

   const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;
      onFilterChange({ ...filters, [name]: value });
   };

   const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      onFilterChange({ ...filters, [name]: checked });
   };

   return <_DivUNSAFE className="p-2 border-b border-slate-300">SEARCH</_DivUNSAFE>;
}
