import { Query } from "@directus/sdk";
import { current } from "@reduxjs/toolkit";
import { Editor } from "@tiptap/core";
import { getMySession } from "app/(platform)/(authentication)/_helpers/getMySession";
import { apiWS } from "app/(platform)/@search/_api/apiWS";
import { tryDirectusWS } from "app/_lib/directus/ws";
import ILog from "app/_lib/global/Log";
import { TAGS } from "app/_services/redux/api/TAGS";
import { REST } from "app/_services/rest/REST";
import { PA } from "app/_types/PATypes";
import { M } from "app/_types/Schema";
import { handleRTKError, handleRTKTags } from "../../../../../_services/redux/api/_helpers/rtkHelpers";
import { apiService } from "../../../../../_services/redux/api/apiService";

export const apiChat = apiService.injectEndpoints({
   endpoints: (builder) => ({
      getManyChatWS: builder.query<
         M.Chat[],
         {
            query: Query<M.CustomDirectusTypes, M.Chat[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         extraOptions: {
            maxRetries: 3
         },
         queryFn: async (arg, api, extraOptions, baseQuery) => {
            try {
               if (!arg.role) throw new Error("no token, roleId, or role found");

               const res = (await api
                  .dispatch(
                     apiWS.endpoints.emitWSMessage.initiate({
                        role: arg.role,
                        message: {
                           type: "items",
                           action: "read",
                           collection: "chat",
                           query: arg.query
                        }
                     })
                  )
                  .unwrap()) as M.Chat[] | null;

               if (!res) throw new Error("No data found");

               return { data: res };
            } catch (e) {
               return handleRTKError({ error: e, endpoint: "apiChat_getManyWS" });
            }
         },
         async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getCacheEntry, dispatch, extra }) {
            await tryDirectusWS<M.Chat>({
               dispatch,
               cacheDataLoaded,
               role: arg.role,
               getCacheEntry,
               collection: "chat",
               query: arg.query,
               onUpdateCachedData(message) {
                  const { type, data, uid, event, status } = message;
                  if (event === "init" && data?.length > 0) {
                     // updateCachedData((draft) => {
                     //    ILog.v("updateCachedData init document", { data, draft: current(draft) });
                     //    return data;
                     // });
                  } else if (event === "create") {
                     updateCachedData((draft) => {
                        // const updated = data;

                        let entries = draft?.filter((d) => !data.some((u) => u.id === d.id));
                        ILog.v("updateCachedData create", { data, draft: current(draft), entries });
                        entries?.push(...data);
                        return entries;
                     });
                  } else if (event === "update") {
                     updateCachedData((draft) => {
                        let entries = draft?.filter((d) => !data.some((u) => u.id === d.id));
                        entries?.push(...data);
                        ILog.v("updateCachedData update", { entries, data, draft: current(draft) });
                        return entries;
                        // draft?.filter((d) => !data.some((u) => u.id === d.id));
                        // //@ts-expect-error
                        // return draft?.push(...data);
                        // // return entries;
                     });
                  } else if (event === "delete") {
                     updateCachedData((draft) => {
                        let entries = draft?.filter((r) => !(data as unknown as string[]).includes(r?.id!));
                        ILog.v("updateCachedData delete", { data, draft: current(draft) });
                        return entries;
                        // ILog.v("updateCachedData delete-FINAL", { data, draft: current(draft) });
                     });
                  }
               },
               uidArgs: "",
               cacheEntryRemoved
            });
         },

         providesTags: (result, error, query) => {
            return handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               tagSelectors: [{ collectionTag: TAGS.CHAT, includeList: true }]
            });
         }
      }),
      getOneChat: builder.query<
         M.Chat | null,
         {
            query?: Query<M.CustomDirectusTypes, M.Chat[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
            id?: string;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               const res = await REST.ChatService.getOne({
                  token: token,
                  query: arg.query,
                  id: arg.id
               });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getOneChat" });
            }
         },

         providesTags: (result, error, query) => handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query, resultTags: result && [{ type: TAGS.CHAT, id: result.id }] })
      }),
      getManyChat: builder.query<
         M.Chat[],
         {
            query: Query<M.CustomDirectusTypes, M.Chat[]>;
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               const res = await REST.ChatService.getMany({
                  token: token,
                  query: arg.query
               });
               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "getManyChat" });
            }
         },

         providesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query, resultTags: result && result.map((r) => ({ type: TAGS.CHAT, id: r.id })) })
      }),
      updateChatById: builder.mutation<M.Chat, { role: PA.RoleQueries | undefined; token?: string | undefined; id: string; payload: Partial<M.Chat> }>({
         queryFn: async (arg, api) => {
            try {
               const { token } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const response = await REST.ChatService.updateById({ token, id: arg.id, payload: arg.payload });
               return { data: response };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "updateChatById" });
            }
         },
         invalidatesTags: (result, error, query) => handleRTKTags<typeof result, typeof error, typeof query>({ result, error, query, resultTags: result && [{ type: TAGS.CHAT, id: result.id }] })
      }),
      createChat: builder.mutation<
         M.Chat,
         {
            role: PA.RoleQueries | undefined;
            token?: string | undefined;
            payload: M.IOmit<M.Chat>;
            editor: Editor;
            documentId: string;

            onCreate: (editor: Editor, chat: M.Chat) => void;
         }
      >({
         queryFn: async (arg, api) => {
            try {
               const { token } = await getMySession({ role: arg.role, tokenOverride: arg.token, dispatch: api.dispatch });
               if (!token) throw new Error(TAGS.UNKNOWN_ERROR);
               const res = await REST.ChatService.create({ token: token!, payload: arg.payload });

               ILog.v("createChat", { res });
               arg.onCreate(arg.editor, res);

               return { data: res };
            } catch (error: any) {
               return handleRTKError({ error, endpoint: "createChat" });
            }
         },
         invalidatesTags: (result, error, query) =>
            handleRTKTags<typeof result, typeof error, typeof query>({
               result,
               error,
               query,
               resultTags: result && [{ type: TAGS.CHAT, id: result.id }]
            })
      })
   }),
   overrideExisting: true
});
