import ILog from "app/_lib/global/Log";
import { M } from "app/_types/Schema";
import { v4 } from "uuid";
import { ActivitySpecNodeAttributesRuntime, AssetNodeAttributesRuntime } from "../_components/nodes/defaultNodeAttributes";
import { calculateActivityPrefixDefined } from "../activities/_helpers/calculateActivityType";

interface CalculateNodeAttributesVersionToAttemptParams {
   attemptSection: M.Section;

   defaultAttributes: ActivitySpecNodeAttributesRuntime;
}
export function calculateNodeAttributesVersionToAttempt({ attemptSection, defaultAttributes }: CalculateNodeAttributesVersionToAttemptParams): ActivitySpecNodeAttributesRuntime {
   const attributes = {
      ...defaultAttributes,
      activitySpecId: v4(),
      attemptId: attemptSection.section_activity_attempt as string,
      sectionId: attemptSection.id,
      // section_section_asset: attemptSection.section_section_asset[0],
      sectionRun: attemptSection.section_run,
      specType: "attempt"
   } satisfies ActivitySpecNodeAttributesRuntime;

   ILog.v("calculateNodeAttributesVersionToAttempt", { attemptSection, defaultAttributes, attributes });
   return attributes;
}

interface CalculateAssetNodeAttributesVersionToAttemptParams {
   attemptSection: M.Section;

   defaultAttributes: AssetNodeAttributesRuntime;
}
export function calculateAssetNodeAttributesVersionToAttempt({ attemptSection, defaultAttributes }: CalculateAssetNodeAttributesVersionToAttemptParams): AssetNodeAttributesRuntime {
   const attributes = {
      ...defaultAttributes,
      assetNodeId: v4(),
      attemptId: attemptSection.section_activity_attempt as string,
      sectionId: attemptSection.id,
      section_section_asset: attemptSection.section_section_asset[0]
   } satisfies AssetNodeAttributesRuntime;

   ILog.v("calculateAssetNodeAttributesVersionToAttempt", { attemptSection, defaultAttributes, attributes });
   return attributes;
}
interface CalculateNodeAttributesParams {
   step: M.Section;
   attempt?: M.ActivityAttempt;
   template: M.ActivityVersion;
   item: M.SectionDocumentEdit;
   isDL: boolean;
}
export function calculateNodeAttributes({ attempt, template, step, item, isDL }: CalculateNodeAttributesParams): ActivitySpecNodeAttributesRuntime {
   const prefix = calculateActivityPrefixDefined(template.activity_version_type);
   const res = {
      activitySpecId: attempt ? v4() : "NULL",
      sectionId: step.id,
      teamId: step.section_activity_control as string,
      templateId: step.section_activity_version as string,
      attemptId: attempt?.id as string | undefined,
      campaignId: template.activity_version_campaign,
      editionId: undefined,
      versionSpaceId: template.activity_version_space[0] as string,
      attemptSpaceId: attempt?.activity_attempt_space[0] as string | undefined,
      stepDocumentId: undefined,
      activityPrefix: prefix,
      deliverableContent: undefined,
      deliverableDocumentId: (item?.section_document_edit_document as M.Document).id,
      focusDeliverableTag: isDL,
      instructionsContent: undefined,
      instructionsDocumentId: (step.section_instructions_document as M.Document).id,
      sectionRun: step.section_run,
      specType: attempt ? "attempt" : "version",
      nodeType: "ActivitySpec"
   } satisfies ActivitySpecNodeAttributesRuntime;
   ILog.v("calculateNodeAttributes", { res, item, step, template, attempt, isDL });
   return res;
}

interface ValidateSpecNodeAttributesParams {
   attributes: ActivitySpecNodeAttributesRuntime;
}
export function validateSpecNodeAttributes({ attributes }: ValidateSpecNodeAttributesParams): ActivitySpecNodeAttributesRuntime | undefined {
   ILog.v("validateSpecNodeAttributes", { attributes });
   if (attributes.activitySpecId === undefined) return undefined;
   if (attributes.activityPrefix === undefined) return undefined;
   // if (attributes.attemptId === undefined) return undefined;
   if (attributes.teamId === undefined) return undefined;
   if (attributes.templateId === undefined) return undefined;
   // if (attributes.focusDeliverableTag === undefined) return undefined;
   if (attributes.sectionId === undefined) return undefined;
   if (attributes.specType === undefined) return undefined;
   if (attributes.campaignId === undefined) return undefined;
   if (attributes.versionSpaceId === undefined) return undefined;
   if (attributes.instructionsDocumentId === undefined) return undefined;
   if (attributes.deliverableDocumentId === undefined) return undefined;
   if (attributes.nodeType === undefined) return undefined;
   // if (attributes.id === undefined) return undefined;
   // if (attributes.sectionRun === undefined) return undefined;
   // if (attributes.attemptSpaceId === undefined) return undefined;
   // if (attributes.editionId === undefined) return undefined;
   // if(attributes.deliverableContent === undefined) return undefined;
   // if(attributes.instructionsContent === undefined) return undefined;
   // if (attributes.stepDocumentId === undefined) return undefined;
   return attributes;
}
