import { PublicEnv } from "app/_lib/global/PublicEnv";
import { ActivityPrefix } from "app/_types/Schema";
import { ActivitySpecNodeAttributesRuntime } from "../_components/nodes/defaultNodeAttributes";
import { validateSpecNodeAttributes } from "./nodeAttributes";

export function replaceURLs(message: string) {
   // 100 superscriptVariants starting from 1
   let superscriptVariants = [
      "¹",
      "²",
      "³",
      "⁴",
      "⁵",
      "⁶",
      "⁷",
      "⁸",
      "⁹",
      "¹⁰",
      "¹¹",
      "¹²",
      "¹³",
      "¹⁴",
      "¹⁵",
      "¹⁶",
      "¹⁷",
      "¹⁸",
      "¹⁹",
      "²⁰",
      "²¹",
      "²²",
      "²³",
      "²⁴",
      "²⁵",
      "²⁶",
      "²⁷",
      "²⁸",
      "²⁹",
      "³⁰",
      "³¹",
      "³²",
      "³³",
      "³⁴",
      "³⁵",
      "³⁶",
      "³⁷",
      "³⁸",
      "³⁹",
      "⁴⁰",
      "⁴¹",
      "⁴²",
      "⁴³",
      "⁴⁴",
      "⁴⁵",
      "⁴⁶",
      "⁴⁷",
      "⁴⁸",
      "⁴⁹",
      "⁵⁰",
      "⁵¹",
      "⁵²",
      "⁵³",
      "⁵⁴",
      "⁵⁵",
      "⁵⁶",
      "⁵⁷",
      "⁵⁸",
      "⁵⁹",
      "⁶⁰",
      "⁶¹",
      "⁶²",
      "⁶³",
      "⁶⁴",
      "⁶⁵",
      "⁶⁶",
      "⁶⁷",
      "⁶⁸",
      "⁶⁹",
      "⁷⁰",
      "⁷¹",
      "⁷²",
      "⁷³",
      "⁷⁴",
      "⁷⁵",
      "⁷⁶",
      "⁷⁷",
      "⁷⁸",
      "⁷⁹",
      "⁸⁰",
      "⁸¹",
      "⁸²",
      "⁸³",
      "⁸⁴",
      "⁸⁵",
      "⁸⁶",
      "⁸⁷",
      "⁸⁸",
      "⁸⁹",
      "⁹⁰",
      "⁹¹",
      "⁹²",
      "⁹³",
      "⁹⁴",
      "⁹⁵",
      "⁹⁶",
      "⁹⁷",
      "⁹⁸",
      "⁹⁹",
      "¹⁰⁰"
   ];

   let urlRegex = /cite:(((https?:\/\/)|(http?:\/\/)|(www\.))[^\s]+)([^. ])/g;

   /*  

  // https://regex101.com
Has a space before periodcite:www.whitehouse.gov .
FAILS: Has a period at endcite:www.whitehouse.gov.
FAILS: Has a period at end of trailing slashcite:www.whitehouse.gov/.
FAILS: Has a trailing slashcite:www.whitehouse.gov/ 
FAILS: Trailing periodscite:www.whitehouse.gov/url/./more/./...
Trailing periodcite:www.whitehouse.gov/url/./more/./.  
Double slash periodcite:www.whitehouse.gov/url/./more//.  
Character periodcite:www.whitehouse.gov/url/./more//w.
*/
   let matches = message.match(urlRegex);
   // replace matches with superscript numbers
   if (matches) {
      let urls = matches.map((hyperlink: any, index: number) => {
         return `${superscriptVariants[index]}`;
      });
      return message.replace(urlRegex, (match: string) => {
         let index = matches?.indexOf(match);
         if (index) {
            return urls[index];
         } else return match;
      });
   } else {
      return message;
   }
}

export function detectURLs(message: string) {
   // /(((https?:\/\/)|(http?:\/\/)|(www\.))[^\s]+)/g;

   let urlRegex = /(((www\.))[^\s]+)([^. ])/g;

   let matches = message.match(urlRegex);
   if (matches) {
      let potluckRedirectPage = `${PublicEnv.ApplicationBaseURL}/redirect?url=`;
      let urls = matches.map((hyperlink: string, index: number) => {
         let url = hyperlink;
         if (!url.match("^https?://|^http?://")) {
            url = "https://" + url;
         }
         return '<a href="' + potluckRedirectPage + url + `" target="${potluckRedirectPage}${url}" rel="noopener noreferrer">` + `[${index + 1}] ` + url + "</a>";
      });

      return urls;
   } else {
      return [];
   }
}

export function isPotluckURL(url: string) {
   let urlRegex = PublicEnv.NodeEnv === "development" ? /localhost:3000/g : /https:\/\/www\.potluckaction\.com/g;
   let urlRegex2 = /https:\/\/www\.potluckaction\.com/g;
   let match1 = url.match(urlRegex);
   let match2 = url.match(urlRegex2);
   if (match1 || match2) {
      return true;
   } else {
      return false;
   }
}

export function isPotluckDeliverableTagURL(url: string) {
   let urlRegex = PublicEnv.NodeEnv === "development" ? /localhost:3000/g : /https:\/\/www\.potluckaction\.com/g;
   let urlRegex2 = /https:\/\/www\.potluckaction\.com/g;
   let match1 = url.match(urlRegex);
   let match2 = url.match(urlRegex2);
   if (match1 || match2) {
      const urlParams = new URLSearchParams(url.split("?")[1]);
      const activitySpecId = urlParams.get("activitySpecId");
      const attemptId = urlParams.get("attemptId");
      const attemptSpaceId = urlParams.get("attemptSpaceId");
      const editionId = urlParams.get("editionId");
      let params = {
         teamId: urlParams.get("teamId") || undefined,
         activityPrefix: urlParams.get("activityPrefix") as ActivityPrefix,
         templateId: urlParams.get("templateId") || undefined,
         focusDeliverableTag: urlParams.get("focusDeliverableTag") === "true",
         sectionId: urlParams.get("sectionId") || undefined,
         sectionRun: Number(urlParams.get("sectionRun")),
         specType: urlParams.get("specType") as ActivitySpecNodeAttributesRuntime["specType"],
         campaignId: urlParams.get("campaignId") || undefined,
         stepDocumentId: urlParams.get("stepDocumentId") || undefined,
         deliverableDocumentId: urlParams.get("deliverableDocumentId") || undefined,
         instructionsDocumentId: urlParams.get("instructionsDocumentId") || undefined,
         nodeType: "ActivitySpec",
         versionSpaceId: urlParams.get("versionSpaceId") || undefined
      } satisfies Partial<ActivitySpecNodeAttributesRuntime>;
      // const res = validateParams<ActivitySpecNodeAttributesRuntime>(params);
      const customNodeAttrs: ActivitySpecNodeAttributesRuntime = {
         ...params,
         activitySpecId,
         attemptId: attemptId !== "undefined" && attemptId !== null && attemptId !== "null" ? attemptId : undefined,
         attemptSpaceId: attemptSpaceId !== "undefined" && attemptSpaceId !== null && attemptSpaceId !== "null" ? attemptSpaceId : undefined,
         editionId: editionId !== "undefined" && editionId !== null && editionId !== "null" ? editionId : undefined,
         deliverableContent: undefined,
         instructionsContent: undefined
      } as ActivitySpecNodeAttributesRuntime;
      const finalAttrs = validateSpecNodeAttributes({ attributes: customNodeAttrs });
      if (finalAttrs) {
         return true;
      }
   }
   return false;
}
