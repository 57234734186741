export class RegexApi {
   static matchUrlsFromText(text: string): string[] {
      const urlRegex = /https?:\/\/[^\s]+/g;
      return text.match(urlRegex) || [];
   }

   public static replaceUrlsInText(text: string, replaceWith: (url: string) => string): string {
      const urls = RegexApi.matchUrlsFromText(text);
      let newText = text;
      urls.forEach((url) => {
         newText = newText.replace(url, typeof replaceWith === "function" ? replaceWith(url) : replaceWith);
      });
      return newText;
   }
}
