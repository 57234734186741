import { ActivityPrefix, AttemptType, ControlType, VersionType } from "app/_types/Schema";

export function calculateActivityPrefix(type: string | ControlType | VersionType | AttemptType | null | undefined | ActivityPrefix): ActivityPrefix | undefined {
   if (!type) return undefined;

   // The type is in snake case, so return the first part of the string

   const split = type.split("_");
   const prefix = split[0] as ActivityPrefix;
   return prefix;
}

export function calculateActivityPrefixDefined(type: string | ControlType | VersionType | AttemptType | null | undefined | ActivityPrefix): ActivityPrefix {
   if (!type) throw new Error("Type is undefined");

   // The type is in snake case, so return the first part of the string

   const split = type.split("_");
   const prefix = split[0] as ActivityPrefix;
   return prefix;
}
