import { Content, Editor, Extension } from "@tiptap/core";
import { ReactRenderer } from "@tiptap/react";
import Suggestion from "@tiptap/suggestion";
import { PluginKey } from "prosemirror-state";
import tippy, { GetReferenceClientRect, Instance, Props } from "tippy.js";

import ILog from "app/_lib/global/Log";
import { SearchContainer } from "./SuggestionDropdown";

interface SuggestionSearchProps {
   title: string;
   query: string;
   command: (a: { editor: Editor; range: Range; props: any }) => void;
}
const config: Content = {
   type: "paragraph"
};
export const ActivitySearch = Extension.create({
   name: "ActivitySearch",
   addKeyboardShortcuts() {
      return {
         Backspace: () =>
            this.editor.commands.command(({ tr, state }) => {
               let isMention = false;
               const { selection } = state;
               const { empty, anchor } = selection;

               if (!empty) {
                  return false;
               }

               state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
                  if (node.type.name === this.name) {
                     isMention = true;
                     tr.insertText(this.options.suggestion.char || "", pos, pos + node.nodeSize);

                     return false;
                  }
               });

               return isMention;
            })
      };
   },
   addProseMirrorPlugins() {
      return [
         Suggestion<any>({
            pluginKey: new PluginKey("ActivitySearchPluginKey"),
            char: "@",
            editor: this.editor,
            startOfLine: true,
            allowSpaces: true,

            command: ({ editor, range, props }) => {
               ILog.v("ActivitySearch pluginCommand", { editor, range, props });
               // props.command({ editor, range, props });
               let content: Content = [
                  {
                     type: "ActivitySpec",
                     attrs: props
                  }
               ];
               content.unshift(config);
               content.push(config);
               // const instructionsJsonContent = selected.content().toJSON()?.content?.[0];
               editor.chain().focus().deleteRange(range).insertContentAt(range.from, content, { applyPasteRules: false, applyInputRules: false, updateSelection: false }).run();
            },
            allow: ({ state, range, editor }) => {
               const node = state.selection.$from.node();
               if (!node) {
                  return false;
               }
               return node.textBetween(0, 1) === "@";
            },
            render: () => {
               let component: ReactRenderer<any, any> | null;
               let popup: Instance<Props> | null;

               return {
                  onStart: (props) => {
                     component = new ReactRenderer(SearchContainer, {
                        props,
                        editor: props.editor
                     });
                     if (!props.clientRect) {
                        return;
                     }
                     popup = tippy(props.editor.options.element, {
                        getReferenceClientRect: props?.clientRect as GetReferenceClientRect,
                        content: component.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: "manual",
                        placement: "bottom-start",
                        theme: "custom-none",
                        onShow: () => {
                           // If a user deletes the @ symbol quickly after
                           // typing it, the node we want to append the popup
                           // to won't exist so we check to ensure it is there
                           // prior to mounting the popup to the DOM
                           try {
                              props.clientRect?.();
                           } catch {
                              return false;
                           }
                        }
                     });
                  },
                  onUpdate: (props) => {
                     component?.updateProps(props);
                     const clientRect = props.clientRect;

                     let domRect: DOMRect | null = null;
                     if (typeof clientRect === "function") {
                        domRect = clientRect();
                     }

                     if (domRect && popup) {
                        popup?.setProps({
                           getReferenceClientRect: props?.clientRect as GetReferenceClientRect
                        });
                     }
                  },
                  onKeyDown: ({ event }) => {
                     if (event.key === "Escape") {
                        if (popup) {
                           popup?.hide();
                        }
                        return true;
                     }
                     if (component?.ref) {
                        return component.ref.onKeyDown(event as KeyboardEvent);
                     } else {
                        return true;
                     }
                  },
                  onExit: () => {
                     if (component) {
                        component.destroy();
                     }
                     if (popup) {
                        popup?.destroy();
                     }
                     component = null;
                     popup = null;
                  }
               };
            }
         })
      ];
   }
});

// const MentionSuggestion = Node.create<MentionOptions>({
//    name: "mentionSuggestion",
//    group: "inline",
//    inline: true,
//    selectable: false,
//    atom: true,
//    addOptions() {
//       return {
//          suggestion: {
//             char: "@",
//             allowSpaces: true,
//             pluginKey: new PluginKey("emojiSuggestion"),
//             command: ({ editor, range, props }) => {

//                const nodeAfter = editor.view.state.selection.$to.nodeAfter;
//                const overrideSpace = nodeAfter?.text?.startsWith(" ");

//                if (overrideSpace) {
//                   range.to += 1;
//                }

//                editor
//                   .chain()
//                   .focus()
//                   .insertContentAt(range, [
//                      {
//                         type: "mentionSuggestion",
//                         attrs: props
//                      },
//                      {
//                         type: "text",
//                         text: " "
//                      }
//                   ])
//                   .run();
//             },
//             allow: ({ editor, range }) => {
//                return editor.can().insertContentAt(range, { type: "mentionSuggestion" });
//             },
//             items: ({ query }) => {
//                return MENTION_SUGGESTIONS.filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()));
//             },
//             // render: () => {
//             //    let reactRenderer: ReactRenderer<SuggestionDropdownRef>;
//             //    let popup: Instance<Props>[];

//             //    return {
//             //       onStart: (props) => {
//             //          reactRenderer = new ReactRenderer(SearchContainer, {
//             //             props,
//             //             editor: props.editor
//             //          });

//             //          popup = tippy("body", {
//             //             getReferenceClientRect: props.clientRect,
//             //             appendTo: () => document.body,
//             //             content: reactRenderer.element,
//             //             showOnCreate: true,
//             //             interactive: true,
//             //             trigger: "manual",
//             //             placement: "bottom-start"
//             //          });
//             //       },
//             //       onUpdate(props) {
//             //          reactRenderer.updateProps(props);

//             //          popup[0]?.setProps({
//             //             getReferenceClientRect: props.clientRect
//             //          });
//             //       },
//             //       onKeyDown(props) {
//             //          if (props.event.key === "Escape") {
//             //             popup[0].hide();
//             //             return true;
//             //          }

//             //          return Boolean(reactRenderer.ref?.onKeyDown(props));
//             //       },
//             //       onExit() {
//             //          popup[0].destroy();
//             //          reactRenderer.destroy();
//             //       }
//             //    };
//             // }
// render: () => {
//    let component: ReactRenderer<any, any>;
//    let popup: Instance<Props>;

//    return {
//       onStart: (props) => {
//          component = new ReactRenderer(SearchContainer, {
//             props,
//             editor: props.editor
//          });
//          popup = tippy(props.editor.options.element, {
//             getReferenceClientRect: props.clientRect as GetReferenceClientRect,
//             content: component.element,
//             showOnCreate: true,
//             interactive: true,
//             trigger: "manual",
//             placement: "bottom-start"
//          });
//       },
//       onUpdate: (props) => {
//          component?.updateProps(props);
//          const clientRect = props.clientRect;

//          let domRect: DOMRect | null = null;
//          if (typeof clientRect === "function") {
//             domRect = clientRect();
//          }

//          if (domRect) {
//             popup?.setProps({
//                getReferenceClientRect: props.clientRect as GetReferenceClientRect
//                // getReferenceClientRect: domRect
//             });
//          }
//       },
//       onKeyDown: ({ event }) => {
//          if (event.key === "Escape") {
//             popup.hide();
//             return true;
//          }
//          if (component.ref) {
//             return component.ref.onKeyDown(event as KeyboardEvent);
//          } else {
//             return true;
//          }
//       },
//       onExit: () => {
//          component.destroy();
//          popup.destroy();
//       }
//    };
// }
//          }
//       };
//    },
//    addAttributes() {
//       return {
//          id: {
//             default: null,
//             renderHTML: (attributes) => ({
//                "data-user-id": attributes.id
//             })
//          },
//          name: {
//             default: null,
//             parseHTML: (element) => element.getAttribute("aria-label")?.split(/\s(.+)/)[1],
//             renderHTML: (attributes) => ({
//                "aria-label": `Name: ${attributes.name}`
//             })
//          }
//       };
//    },
//    parseHTML() {
//       return [{ tag: "span[data-mention]" }];
//    },
//    renderHTML({ node, HTMLAttributes }) {
//       return [
//          "span",
//          mergeAttributes({ "data-mention": "", class: "p-2 rounded-md bg-slate-300" }, HTMLAttributes),
//          ["span", { class: "char" }, this.options.suggestion.char],
//          ["span", { class: "name" }, node.attrs.name]
//       ];
//    },
//    renderText({ node }) {
//       return `${this.options.suggestion.char}${node.attrs.name}`;
//    },
// addKeyboardShortcuts() {
//    return {
//       Backspace: () =>
//          this.editor.commands.command(({ tr, state }) => {
//             let isMention = false;
//             const { selection } = state;
//             const { empty, anchor } = selection;

//             if (!empty) {
//                return false;
//             }

//             state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
//                if (node.type.name === this.name) {
//                   isMention = true;
//                   tr.insertText(this.options.suggestion.char || "", pos, pos + node.nodeSize);

//                   return false;
//                }
//             });

//             return isMention;
//          })
//    };
// },
//    addProseMirrorPlugins() {
//       return [
//          Suggestion({
//             editor: this.editor,
//             ...this.options.suggestion
//          })
//       ];
//    }
// });
